/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const companyInfoIdeal = [
  { label: 'Investor Role', key: 'investor_role', value: 'investor_role' },
  { label: 'Silent', key: 'silent', value: 'silent' },
  { label: 'Daily Involvement', key: 'daily_involvement', value: 'daily_involvement' },
  { label: 'Weekly Involvement', key: 'weekly_involvement', value: 'weekly_involvement' },
  { label: 'Monthly Involvement', key: 'monthly_involvement', value: 'monthly_involvement' },
  { label: 'Any', key: 'any', value: 'any' },
];
export const companyInfoStage = [
  { label: 'Pre-Startup / R&D', key: 'pre_startup', value: 'pre_startup' },
  { label: 'MVP/ Finished Product', key: 'finished_product', value: 'finished_product' },
  { label: 'Achieving Sales', key: 'achieving_sales', value: 'achieving_sales' },
  { label: 'Breaking Even', key: 'breaking_even', value: 'breaking_even' },
  { label: 'Profitable', key: 'profitable', value: 'profitable' },
  { label: 'Other', key: 'other', value: 'other' },
];
export const companyInfoIndustries = [
  {
    key: 'agriculture',
    value: 'agriculture',
    label: 'Agriculture',
  },
  {
    key: 'energy_&_natural_resources',
    value: 'energy_&_natural_resources',
    label: 'Energy & Natural Resources',
  },
  {
    key: 'finance',
    value: 'finance',
    label: 'Finance',
  },
  {
    key: 'manufacturing_&_engineering',
    value: 'manufacturing_&_engineering',
    label: 'Manufacturing & Engineering',
  },
  {
    key: 'personal_services',
    value: 'personal_services',
    label: 'Personal Services',
  },
  {
    key: 'retail',
    value: 'retail',
    label: 'Retail',
  },
  {
    key: 'technology',
    value: 'technology',
    label: 'Technology',
  },
  {
    key: 'entertainment_&_leisure',
    value: 'entertainment_&_leisure',
    label: 'Entertainment & Leisure',
  },
  {
    key: 'food_&_beverage',
    value: 'food_&_beverage',
    label: 'Food & Beverage',
  },
  {
    key: 'media',
    value: 'media',
    label: 'Media',
  },
  {
    key: 'products_&_inventions',
    value: 'products_&_inventions',
    label: 'Products & Inventions',
  },
  {
    key: 'sales_&_marketing',
    value: 'sales_&_marketing',
    label: 'Sales & Marketing',
  },
  {
    key: 'transportation',
    value: 'transportation',
    label: 'Transportation',
  },
  {
    key: 'education_&_training',
    value: 'education_&_training',
    label: 'Education & Training',
  },
  {
    key: 'fashion_&_beauty',
    value: 'fashion_&_beauty',
    label: 'Fashion & Beauty',
  },
  {
    key: 'hospitality',
    value: 'hospitality',
    label: 'Hospitality',
  },
  {
    key: '_restaurants_&_bars',
    value: '_restaurants_&_bars',
    label: ' Restaurants & Bars',
  },
  {
    key: 'medical_&_science',
    value: 'medical_&_science',
    label: 'Medical & Science',
  },
  {
    key: 'property',
    value: 'property',
    label: 'Property',
  },
  {
    key: 'software',
    value: 'software',
    label: 'Software',
  },
];
export const industry = [
  { label: 'Gyms (Personal Fitness, Boxing, Martial Arts, etc)', value: 'gym' },
  { label: 'Salons (Hairdressers and Barbers)', value: 'salons' },
  { label: 'Restaurants/ Coffee Shops/ Bars', value: 'restaurants' },
  { label: 'Offices', value: 'offices' },
  { label: 'Retail', value: 'retail' },
  { label: 'Warehouses/ Industrial', value: 'warehouses' },
  { label: 'Event Spaces (Functions, Birthdays, Christenings etc)', value: 'eventSpaces' },
  { label: 'Shopping Centres', value: 'shopping_centres' },
  { label: 'Fashion', value: 'fashion' },
  { label: 'Commercial', value: 'commercial' },
  { label: 'Gallery/Studio', value: 'galleryStudio' },
  { label: 'Hotels', value: 'hotels' },
  { label: 'Medical/ Consulting (Physio, Massage therapists etc)', value: 'medicalConsulting' },
  { label: 'Showrooms/ Bulky Goods', value: 'showrooms' },
  { label: 'Production/ Shooting/ Advertising/ Media', value: 'production' },
  { label: 'Meetings/ Conferences', value: 'meetings' },
  { label: 'Education (Tutors)', value: 'education' },
];

export const amenties = [
  { key: 'fitting_room', label: 'Fitting Room' },
  { key: 'fitted_kitchen', label: 'Fitted Kitchen' },
  { key: 'counter', label: 'Counter' },
  { key: 'video_projector', label: 'Video Projector' },
  { key: 'sound_equipment', label: 'Sound Equipment' },
  { key: 'wifi', label: 'Wifi' },
  { key: 'terrace', label: 'Terrace' },
  { key: 'air_conditioned', label: 'Air Conditioned' },
  { key: 'lighting', label: 'Lighting' },
  { key: 'restroom', label: 'Restroom' },
  { key: 'heating', label: 'Heating' },
  { key: 'driveway', label: 'Driveway' },
  { key: 'street_level', label: 'Street Level' },
  { key: 'reception_desk', label: 'Reception Desk' },
  { key: 'furnished', label: 'Furnished' },
  { key: '24/7_access', label: '24/7 Access' },
  { key: 'code_access', label: 'Code Access' },
  { key: 'lift_access', label: 'Lift Access' },
  { key: 'parking', label: 'Parking' },
  { key: 'wheelchair_access', label: 'Wheelchair Access' },
  { key: 'breakout_space', label: 'Breakout Space' },
  { key: 'coffee_machine', label: 'Coffee Machine' },
  { key: 'fridge', label: 'Fridge' },
  { key: 'outdoor_space', label: 'Outdoor Space' },
  { key: 'bathroom', label: 'Bathroom' },
  { key: 'shower', label: 'Shower' },
  { key: 'cafe', label: 'Cafe(s)' },
  { key: 'park', label: 'Park(s)' },
  { key: 'restaurant', label: 'Restaurant/s' },
  { key: 'transport', label: 'Transport' },
  { key: 'water', label: 'Water' },
  { key: 'gas', label: 'Gas' },
  { key: 'electricity', label: 'Electricity' },
  { key: 'security', label: 'Security' },
  { key: 'stock_room', label: 'Stock room' },
  { key: 'basement', label: 'Basement' },
  { key: 'other', label: 'Other' },
].sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

export const spaceTypes = [
  { label: 'Cafe/Restaurant', key: 'cafe_restaurant' },
  { label: 'Commercial Farming', key: 'commercial_farming' },
  { label: 'Gallery/Studio', key: 'gallery_studio' },
  { label: 'Hotel/Leisure', key: 'hotel_leisure' },
  { label: 'Education/ College/ Schools', key: 'education' },
  { label: 'Industrial/Warehouse', key: 'industrial_warehouse' },
  { label: 'Land/Development', key: 'land_development' },
  { label: 'Medical/Consulting', key: 'dedical_consulting' },
  { label: 'Offices', key: 'offices' },
  { label: 'Retail', key: 'retail' },
  { label: 'Showrooms/Bulky Goods', key: 'showrooms_bulky_goods' },
  { label: 'Shopping Centre', key: 'shopping_centre' },
  { label: 'Gyms/ Personal Fitness', key: 'gyms_personal_fitness' },
  { label: 'Salons/ Hairdressers ', key: 'salons_hairdressers ' },
  { label: 'Event Spaces', key: 'event_spaces' },
  { label: 'Kiosk', key: 'kiosk' },
  { label: 'House', key: 'house' },
  { label: 'Terrace / Garden', key: 'terrace_garden' },
  { label: 'kitchen', key: 'kitchen' },
  { label: 'Other', key: 'other' },
].map(item => {
  return { ...item, value: item.key };
});

export const usage = [
  { label: 'Fashion', key: 'fashion' },
  { label: 'Home & Living', key: 'home_&_living' },
  { label: 'Market', key: 'market' },
  { label: 'Events & Entertainment', key: 'events_&_entertainment' },
  { label: 'Art', key: 'art' },
  { label: 'Food & Drink', key: 'food_&_drink' },
  { label: 'Beauty', key: 'beauty' },
  { label: 'Professional Services', key: 'professional_services' },
  { label: 'Education & Community', key: 'education_&_community' },
  { label: 'Agency', key: 'agency' },
  { label: 'Production/ Shooting', key: 'production_shooting' },
  { label: 'Meeting/ Conference/ Training/ Workshop', key: 'meeting_&_training' },
  { label: 'Unique', key: 'unique' },
  { label: 'Advertising/ Media', key: 'advertising' },
  { label: 'Shop Share', key: 'shop_share' },
  { label: 'Other', key: 'other' },
].map(item => {
  return { ...item, value: item.key };
});

export const lengthTypes = [
  { label: 'Less than 1 month', value: 'Less_than_1_month', key: 'Less_than_1_month' },
  { label: '1 - 3 months', value: '1_to_3_months', key: '1_to_3_months' },
  { label: '3 - 12 months', value: '3_to_12_months', key: '3_to_12_months' },
  { label: '12+ months', value: '12+_months', key: '12+_months' },
];

export const listingCategories = [
  { label: 'Listing', key: 'listing' },
  { label: 'Custom Space Enquiry', key: 'requirement' },
];
export const businessListingCategories = [
  { label: 'Business Share', key: 'business_share' },
  { label: 'Invest', key: 'invest' },
];
export const businessShareCategories = [
  {
    key: 'agriculture_and_fisheries',
    label: 'Agriculture and fisheries',
    value: 'agriculture_and_fisheries',
  },
  {
    key: 'banking_and_finance',
    label: 'Banking and finance',
    value: 'banking_and_finance',
  },
  {
    key: 'bonds_and_fixed_income',
    label: 'Bonds and fixed income',
    value: 'bonds_and_fixed_income',
  },
  {
    key: 'currencies',
    label: 'Currencies',
    value: 'currencies',
  },
  {
    key: 'energy_and_utilities',
    label: 'Energy and utilities',
    value: 'energy_and_utilities',
  },
  {
    key: 'food_and_drink',
    label: 'Food and drink',
    value: 'food_and_drink',
  },
  {
    key: 'healthcare',
    label: 'Healthcare',
    value: 'healthcare',
  },
  {
    key: 'index_funds',
    label: 'Index funds',
    value: 'index_funds',
  },
  {
    key: 'infrastructure',
    label: 'Infrastructure',
    value: 'infrastructure',
  },
  {
    key: 'manufacturing',
    label: 'Manufacturing',
    value: 'manufacturing',
  },
  {
    key: 'media',
    label: 'Media',
    value: 'media',
  },
  {
    key: 'media_&_entertainment',
    label: 'Media & entertainment',
    value: 'media_&_entertainment',
  },
  {
    key: 'mining_oil_and_gas',
    label: 'Mining, oil and gas',
    value: 'mining_oil_and_gas',
  },
  {
    key: 'property',
    label: 'Property',
    value: 'property',
  },
  {
    key: 'responsible',
    label: 'Responsible',
    value: 'responsible',
  },
  {
    key: 'retail',
    label: 'Retail',
    value: 'retail',
  },
  {
    key: 'services',
    label: 'Services',
    value: 'services',
  },
  {
    key: 'strategic_funds',
    label: 'Strategic funds',
    value: 'strategic_funds',
  },
  {
    key: 'technology',
    label: 'Technology',
    value: 'technology',
  },
  {
    key: 'telecommunication_services',
    label: 'Telecommunication services',
    value: 'telecommunication_services',
  },
  {
    key: 'tourism',
    label: 'Tourism',
    value: 'tourism',
  },
  {
    key: 'transport',
    label: 'Transport',
    value: 'transport',
  },
  {
    key: 'other_(please_specify)',
    label: 'Other (please specify)',
    value: 'other_(please_specify)',
  },
];
// export const membershipPlans = [
//   { label: 'weekly', description: 'lorem ipsum weekly', price: '$10', planId: 'planid' },
//   { label: 'Monthly', description: 'lorem ipsum monthly', price: '$50', planId: 'planid' },
//   { label: 'Yearly', description: 'lorem ipsum yearly', price: '$300', planId: 'planid' },
// ];

export const pricingOptions = [
  { key: 'enquire_only', label: 'Enquire Only' },
  { key: 'hourly', label: 'Hourly' },
  { key: 'daily', label: 'Daily' },
  { key: 'weekly', label: 'Weekly' },
  { key: 'monthly', label: 'Monthly' },
];
export const Stage = [
  { key: 'pre-startup/r&d', label: 'Pre-Startup/R&D', value: 'pre-startup/r&d' },
  { key: 'mvp/finishedProduct', label: 'MVP/ Finished Product', value: 'mvp/finishedProduct' },
  {
    key: 'achievingSales',
    label: 'AchievingSales',
    value: 'AchievingSales',
  },
  { key: 'breakingEven', label: 'BreakingEven', value: 'breakingEven' },
  { key: 'Profitable', label: 'Profitable', value: 'Profitable' },
  {
    key: 'other',
    label: 'Other(please specify)',
    value: 'other',
  },
];
export const IdealInvestorRole = [
  { key: 'investorRole', label: 'Investor Role', value: 'investorRole' },
  { key: 'silent', label: 'Silent', value: 'silent' },
  { key: 'dailyInvolvement', label: 'Daily Involvement', value: 'dailyInvolvement' },
  { key: 'weeklyinvolvement', label: 'Weekly involvement', value: 'weeklyinvolvement' },
  { key: ' monthlyinvolvement', label: ' Monthly involvement', value: ' monthlyinvolvement' },
  { key: 'any', label: 'Any', value: 'any' },
];
export const filters = [
  {
    id: 'availability',
    label: 'Availability',
    type: 'AvailabilityFilter',
    group: 'primary',
    queryParamNames: ['pub_test'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
    },
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },

  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: listingCategories,
    },
  },
  {
    id: 'sizeOfSpace',
    label: 'Space size',
    type: 'CustomFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_sizeOfSpace'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1,
      max: 20000,
      step: 0.5,
    },
  },
  {
    id: 'capacity',
    label: 'Capacity',
    type: 'CustomFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_capacityOfSpace'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1,
      max: 1000,
      step: 0.5,
    },
  },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },

  {
    id: 'spaceType',
    label: 'Space type',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_spaceType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: spaceTypes,
    },
  },

  {
    id: 'usage',
    label: 'Usage',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_usage'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: usage,
    },
  },
  {
    id: 'length',
    label: 'Length',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_length'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: lengthTypes,
    },
  },

  {
    id: 'amenties',
    label: 'Amenties',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenties'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: amenties,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
