import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingBannerImageForm } from '../../forms';
import { ListingLink } from '..';

import css from './EditListingBannerImagePanel.module.css';
import axios from 'axios';

const EditListingBannerImagePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBannerImagePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingBannerImagePanel.createListingTitle" />
  );
  const BannerImage = publicData?.BannerImage;
  const currentListingId = currentListing.id.uuid;
  const initialValues = { BannerImage };
  const uploadFile = async (file, form) => {
    const formData = new FormData();
    formData.append('BannerImage', file);
    formData.append('listingId', currentListing.id.uuid);
    setUploadError(null);
    setLoading(true);

    try {
      const res = await axios.post('/upload-banner', formData);
      const { link } = res.data;
      form.change('BannerImage', link);
      setUploadError(null);
    } catch (e) {
      console.log(e);
      form.change('BannerImage', '');
      setUploadError('Failed to upload the file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes}>
      <h1 className={classNames(css.title, 'px-16 lg:px-0')}>{panelTitle}</h1>
      <EditListingBannerImageForm
        className={css.form}
        currentListingId={currentListingId}
        initialValues={initialValues}
        uploadFile={uploadFile}
        uploadError={uploadError}
        uploadInProgress={loading}
        onSubmit={values => {
          const { BannerImage } = values;

          const updatedValues = {
            publicData: { BannerImage: BannerImage },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingBannerImagePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingBannerImagePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBannerImagePanel;
