import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  required,
  composeValidators,
  urlValidate,
  fieldSelectModernMandatory,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelectModern } from '../../components';

import css from './EditListingBusinessShareForm.module.css';
import config from '../../config';

const EditListingBusinessShareFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingCategory,
        values,
      } = formRenderProps;
      console.log(values);
      const urlValid = urlValidate('enter correct website');

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionLabel',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const websiteMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.website',
      });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.websitePlaceholder',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const Stage = config.custom.companyInfoStage;
      const IdealInvestorRole = config.custom.IdealInvestorRole;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label="Business Name"
            placeholder="Enter Business Name" //{titlePlaceholderMessage}
            validate={composeValidators(required('Please enter business Name'))}
            autoFocus
          />

          {/* <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          /> */}

          <FieldTextInput
            id="website"
            name="website"
            className={css.description}
            type="text"
            label="Website" //{websiteMessage}
            placeholder={websitePlaceholderMessage}
            validate={composeValidators(urlValid, required('Website is required'))}
          />
          <FieldSelectModern
            className={css.description}
            id="stage"
            name="stage"
            options={Stage}
            label="Stage"
            validate={fieldSelectModernMandatory('Please select a stage.')}
          />
          {values?.stage?.key == 'other' ? (
            <FieldTextInput
              id="other"
              name="other"
              className={css.description}
              type="text"
              label="Other"
              placeholder="please specify"
              validate={composeValidators(required('This field is required.'))}
            />
          ) : null}
          <FieldSelectModern
            className={css.description}
            id="idealInvestorRole"
            name="idealInvestorRole"
            options={IdealInvestorRole}
            label="Ideal Investor Role"
            validate={fieldSelectModernMandatory('Please select an ideal investor role.')}
          />
          {/* <div className={css.description}></div> */}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingBusinessShareFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingBusinessShareFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingBusinessShareFormComponent);
