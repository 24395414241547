import React, { useEffect, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  InlineTextButton,
  IconClose,
  PrimaryButton,
  FieldSelect,
  FieldTimeZoneSelect,
  Modal,
} from '../../components';

import { Select } from 'react-rainbow-components';

import css from './TimesForm.module.css';
import moment from 'moment';
import { extractTimestampFromValues } from '../../util/dates';
import _ from 'lodash';
import {
  checkIfExistsInRange,
  groupSimilarTimeWithZeroDifferenceTogether,
  makeRangeFromSlots,
} from './util';

const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);
const formatToAMPM = timeString => {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
};
const createRequestDatesArray = (day, interval) => {
  let dates = [];
  for (let i = 1; i <= interval; i++)
    dates.push(
      moment(day)
        .add(i, 'days')
        .format('DD-MM-YYYY')
    );
  return dates;
};

function pushTo(
  // enumerationTimes,
  interval,
  intervalType,
  valuesOfThatParticularDay,
  allTimes,
  currentDay
) {
  let durationInterval = interval;
  switch (intervalType) {
    case 'days':
      durationInterval *= 1;
      break;
    case 'weeks':
      durationInterval *= 7;
      break;
    case 'months':
      durationInterval *= 30;
      break;
  }

  let enumerationTimesCopy = [];
  const day = currentDay.clone().format('DD-MM-YYYY');
  // const newVals = valuesOfThatParticularDay?.map();

  const grouped = _.groupBy(allTimes, 'startDay');
  // const newAllTimes = allTimes.filter(a => a.startDay == day && a.endDay == day);

  const range = makeRangeFromSlots(grouped);
  // console.log(range);

  //check if all days exist in range, if not return error;

  const requestDatesArray = createRequestDatesArray(currentDay, durationInterval);
  const allAvailableDates = Object.keys(grouped);
  const isEveryRequestDateInAvailableDates = requestDatesArray.every(d =>
    allAvailableDates.includes(d)
  );

  if (!isEveryRequestDateInAvailableDates) return undefined;

  // let prev = null;
  // let prevPointer = null;
  // let range = [];
  // Object.entries(grouped).forEach(([key, values]) => {
  //   values.forEach(value => {
  //     if (prev && Number(prevPointer.end) - Number(value.start) == 0) {
  //     } else if (prev) {
  //       range.push({
  //         ...prev,
  //         end: value.end,
  //         endTimeOfDay: value.endTimeOfDay,
  //         endDay: value.endDay,
  //       });
  //       prev = null;
  //     }

  //     if (prev == null) {
  //       prev = value;
  //     }
  //     prevPointer = value;
  //   });

  //   range.push({
  //     ...prev,
  //     end: prevPointer.end,
  //     endTimeOfDay: prevPointer.endTimeOfDay,
  //     endDay: prevPointer.endDay,
  //   });

  //   prev = null;
  //   prevPointer = null;
  // });

  // console.log(valuesOfThatParticularDay);

  const newVals = valuesOfThatParticularDay
    .map(v => {
      const start = grouped?.[day]?.find(a => a.startTimeOfDay == v.startTime);
      const end = grouped?.[day]?.find(a => a.endTimeOfDay == v.endTime);
      console.log({ start, end, v });
      return { startTime: start?.start, endTime: end?.end };
    })
    .filter(f => f != undefined);

  let iterator = durationInterval;
  // if (intervalType == 'days') {
  // console.log(interval);
  while (iterator > 0) {
    enumerationTimesCopy.push(
      currentDay
        .clone()
        .add(iterator, 'days')
        .valueOf()
    );
    iterator--;
  }

  let data = [];
  // console.log({ enumerationTimesCopy });
  for (const e of enumerationTimesCopy) {
    const difference = moment.duration(moment(e).diff(currentDay)).asDays();

    data.push(
      newVals.map(v => {
        return {
          startTime: moment(v.startTime)
            .add(difference, 'days')
            .valueOf(),
          endTime: moment(v.endTime)
            .add(difference, 'days')
            .valueOf(),
        };
      })
    );
  }

  const list = data.reduce((acc, curr) => acc.concat(curr), []);
  let itemsExistsInRange = [];
  for (const listItem of list) {
    // console.log(range, listItem);

    //TODO: OLD LOGIC
    // itemsExistsInRange.push({ exists: checkIfExistsInRange(range, listItem), listItem });
    //FIXME: NEWLOGIC
    itemsExistsInRange.push({
      exists: checkIfExistsInRange(groupSimilarTimeWithZeroDifferenceTogether(grouped), listItem),
      listItem,
    });
  }

  if (itemsExistsInRange.every(item => item.exists == true)) {
    return itemsExistsInRange.map(item => ({
      li: item.listItem,
      day: moment(item.listItem.startTime).format('DD-MM-YYYY'),
      startTime: moment(item.listItem.startTime).format('HH:mm'),
      endTime: moment(item.listItem.endTime).format('HH:mm'),
      dayMoment: moment(item.listItem.startTime).format('YYYY-MM-DD'),
    }));
  } else {
    console.log('error');
  }
  // }
}

const HOURS = Array(24).fill();
const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

const sortEntries = (defaultCompareReturn = 0) => (a, b) => {
  if (a.startTime && b.startTime) {
    const aStart = Number.parseInt(a.startTime.split(':')[0]);
    const bStart = Number.parseInt(b.startTime.split(':')[0]);
    return aStart - bStart;
  }
  return defaultCompareReturn;
};

const findEntryFn = entry => e => e.startTime === entry.startTime && e.endTime === entry.endTime;

const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  const entries = values?.[dayOfWeek];
  const currentEntry = entries?.[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry?.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = time => h => h < time;
  const pickBetween = (start, end) => h => h >= start && h < end;

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

const filterEndHours = (availableEndHours, values, dayOfWeek, index) => {
  const entries = values?.[dayOfWeek];
  const currentEntry = entries?.[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry?.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = time => h => h > time;
  const pickBetween = (start, end) => h => h > start && h <= end;

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));
};

const getEntryBoundaries = (values, dayOfWeek, intl, findStartHours) => index => {
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries?.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = Number.parseInt(startTime.split(':')[0]);
      const endHour = Number.parseInt(endTime.split(':')[0]);
      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

const DailyPlan = props => {
  const { dayOfWeek, values, intl, allStartHours, allEndHours, onRemoveDay } = props;
  const getEntryStartTimes = getEntryBoundaries(values, dayOfWeek, intl, true);
  const getEntryEndTimes = getEntryBoundaries(values, dayOfWeek, intl, false);

  const hasEntries = values[dayOfWeek] && values[dayOfWeek][0];

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  return (
    <div className={classNames(css.weekDay, hasEntries ? css.hasEntries : null)}>
      <div className={classNames(css.dayOfWeek)}>
        <strong>{dayOfWeek}</strong>
        {/* <InlineTextButton
          type="button"
          onClick={() => {
            onRemoveDay(dayOfWeek);
          }}
          title="Remove this day"
          className="ml-2 text-2xl font-bold hover:no-underline"
        >
          &times;
        </InlineTextButton> */}
      </div>

      <FieldArray name={dayOfWeek}>
        {({ fields }) => {
          return (
            <div className={css.timePicker}>
              {fields.map((name, index) => {
                // Pick available start hours
                const pickUnreservedStartHours = h =>
                  !(getEntryStartTimes(index) ?? []).includes(h);
                // const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);
                const availableStartHours = allStartHours.filter(pickUnreservedStartHours);

                // Pick available end hours
                const pickUnreservedEndHours = h => !(getEntryEndTimes(index) ?? []).includes(h);
                // const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);
                const availableEndHours = allEndHours.filter(pickUnreservedEndHours);

                return (
                  <div className={css.fieldWrapper} key={name}>
                    <div className={css.formRow}>
                      <div className={css.field}>
                        <FieldSelect
                          id={`${name}.startTime`}
                          name={`${name}.startTime`}
                          selectClassName={css.fieldSelect}
                        >
                          <option disabled value="">
                            {startTimePlaceholder}
                          </option>
                          {filterStartHours(availableStartHours, values, dayOfWeek, index).map(
                            s => (
                              <option value={s} key={s}>
                                {/* {s} */}
                                {formatToAMPM(s)}
                              </option>
                            )
                          )}
                        </FieldSelect>
                      </div>
                      <span className={css.dashBetweenTimes}>-</span>
                      <div className={css.field}>
                        <FieldSelect
                          id={`${name}.endTime`}
                          name={`${name}.endTime`}
                          selectClassName={css.fieldSelect}
                        >
                          <option disabled value="">
                            {endTimePlaceholder}
                          </option>
                          {filterEndHours(availableEndHours, values, dayOfWeek, index).map(s => (
                            <option value={s} key={s}>
                              {/* {s} */}
                              {formatToAMPM(s)}
                            </option>
                          ))}
                        </FieldSelect>
                      </div>
                    </div>
                    <div
                      className={css.fieldArrayRemove}
                      onClick={() => fields.remove(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconClose rootClassName={css.closeIcon} />
                    </div>
                  </div>
                );
              })}

              {fields.length === 0 ? (
                <InlineTextButton
                  type="button"
                  className={css.buttonSetHours}
                  onClick={() => fields.push({ startTime: null, endTime: null })}
                >
                  <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                </InlineTextButton>
              ) : (
                <InlineTextButton
                  type="button"
                  className={css.buttonAddNew}
                  onClick={() => fields.push({ startTime: null, endTime: null })}
                >
                  <FormattedMessage id="EditListingAvailabilityPlanForm.addAnother" />
                </InlineTextButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

const submit = (onSubmit, weekdays) => values => {
  const sortedValues = weekdays.reduce(
    (submitValues, day) => {
      return submitValues[day]
        ? {
            ...submitValues,
            [day]: submitValues[day].sort(sortEntries()),
          }
        : submitValues;
    },
    { ...values }
  );

  onSubmit(sortedValues);
};

const EditListingAvailabilityPlanFormComponent = props => {
  const { onSubmit, ...restOfprops } = props;
  return (
    <FinalForm
      {...restOfprops}
      onSubmit={submit(onSubmit, props.weekdays)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          intl,
          weekdays,
          fetchErrors,
          values,
          allStartHours,
          allEndHours,
          onRemoveDay,
          form,
          allTimes,
          formValues,
          days,
          setDays,
        } = fieldRenderProps;

        const [newGroup, setNewGroup] = useState(null);

        useEffect(() => {
          if (days && newGroup) {
            console.log('inside useEffect', days, newGroup);
            // form.batch(() => {
            Object.entries(newGroup).forEach(([day, entries]) => {
              form.change(
                day,
                entries.map(e => ({ startTime: e.startTime, endTime: e.endTime }))
              );
              // console.log({
              //   [day]: entries.map(e => ({ startTime: e.startTime, endTime: e.endTime })),
              //   formValues,
              // });
              // });
            });
            setNewGroup(null);
          } else {
            console.log('outside useEffect', days, newGroup);
          }
        }, [days, newGroup]);

        const dayExpression = values?.[moment(props.day).format('DD-MM-YYYY')];
        const showRepeat =
          dayExpression?.length > 0 && dayExpression.every(e => e.startTime && e.endTime);
        const classes = classNames(rootClassName || css.root, className);
        const [showRecurringModal, setShowRecurringModal] = useState(false);
        const [interval, setInterval] = useState('');
        const [intervalType, setIntervalType] = useState('');
        const [showRecurringError, setShowRecurringError] = useState(false);

        const validator = () => {
          // console.log({ allTimes, interval, intervalType });
          if (!intervalType || !interval || !allTimes) return;
          // let enumerationTimes = [];
          let change;
          switch (intervalType) {
            case 'days':
              change = pushTo(
                // enumerationTimes,
                interval,
                'days',
                dayExpression,
                allTimes,
                moment(props.day)
              );
              console.log(change);
              break;
            case 'months':
              change = pushTo(interval, 'months', dayExpression, allTimes, moment(props.day));
              break;
            case 'weeks':
              change = pushTo(interval, 'weeks', dayExpression, allTimes, moment(props.day));
              break;
          }

          if (change) {
            console.log('change');
            const newCollectionGroup = _.groupBy(change, 'day');
            const groups = _.groupBy(change, 'dayMoment');
            // console.log(Object.keys(groups));
            // console.log(Object.keys(newGroup), Object.values(newGroup));
            setDays(
              [
                ...new Set(
                  [
                    ...days,
                    ...Object.keys(groups).map(k => new Date(`${k}T06:30:00.000Z`)),
                  ].map(a => a.toISOString())
                ),
              ]
                .map(a => new Date(a).getTime())
                .sort()
                .map(a => new Date(a))
            );

            // console.log(newGroup);
            // form.batch(() => {
            //   Object.entries(newGroup).forEach(([day, entries]) => {
            //     form.change(day, entries);
            //     // console.log({ [day]: entries, formValues });
            //   });
            // });

            setNewGroup(newCollectionGroup);
            return true;
          } else {
            return false;
          }
        };

        const concatDayEntriesReducer = (entries, day) =>
          values[day] ? entries.concat(values[day]) : entries;
        const hasUnfinishedEntries = !!weekdays
          .reduce(concatDayEntriesReducer, [])
          .find(e => !e.startTime || !e.endTime);

        const { updateListingError } = fetchErrors || {};

        const submitDisabled = hasUnfinishedEntries;

        const RecurringModal = (
          <Modal
            isOpen={showRecurringModal}
            onClose={() => setShowRecurringModal(false)}
            id={`recurring-modal-${weekdays?.[0]}`}
            name={`recurring-modal-${weekdays?.[0]}`}
            onManageDisableScrolling={() => {}}
            usePortal
          >
            <h2 className="text-center text-2xl text-black font-bold uppercase">Repeat schedule</h2>

            <div className="flex flex-col">
              <label htmlFor={'intervalType-repeating-modal'}>Select Interval Type</label>
              <select
                value={intervalType}
                id="intervalType-repeating-modal"
                onChange={e => setIntervalType(e.target.value)}
              >
                <option value="" selected hidden>
                  Select...
                </option>
                {['days', 'weeks', 'months'].map(period => (
                  <option key={period} value={period}>
                    {period.charAt(0).toUpperCase() + period.slice(1)}
                  </option>
                ))}
              </select>

              <label htmlFor={'interval-repeating-modal'} className="mt-2">
                Interval
              </label>

              <input
                id="interval-repeating-modal"
                type="number"
                name="count"
                min={1}
                className="mb-2"
                value={interval}
                placeholder="Count..."
                required
                onChange={e => {
                  setInterval(+e.target.value);
                  // console.log(props.day);
                  // const newDay = new Date(
                  //   moment(props.day)
                  //     .add(1, 'days')
                  //     .valueOf()
                  // );
                  // fieldRenderProps.setDays([...props.days, newDay]);
                  // fieldRenderProps.setDays([...props.days, moment(props.day).add(1, 'days')]);
                }}
                onClick={e => e.target.focus()}
                onMouseUp={e => e.target.blur()}
              />

              {showRecurringError && (
                <span className="mt-4 mb-2 text-red-500 leading-snug text-sm">
                  The interval cannot be repeated as the same time on one or more days is not
                  available
                </span>
              )}

              <button
                disabled={!intervalType || !interval}
                className={
                  'flex-1 mt-4 bg-marketplaceColor disabled:bg-gray-300 disabled:pointer-events-none border-0 rounded shadow-md text-white py-2 hover:bg-marketplaceColorDark hover:cursor-pointer'
                }
                type="button"
                onClick={() => {
                  // form.change(
                  //   moment(
                  //     moment(props.day)
                  //       .add(1, 'days')
                  //       .valueOf()
                  //   ).format('DD-MM-YYYY'),
                  //   [{ startTime: '00:00', endTime: '01:00' }]
                  // );
                  setShowRecurringError(false);
                  const validationPassed = validator();
                  if (validationPassed) {
                    setShowRecurringModal(false);
                    setShowRecurringError(false);
                  } else setShowRecurringError(true);
                }}
              >
                Apply
              </button>
            </div>
          </Modal>
        );

        return (
          <Form id={formId} className={classes} onSubmit={handleSubmit}>
            <div className={css.week}>
              {weekdays.map(w => {
                return (
                  <DailyPlan
                    dayOfWeek={w}
                    key={w}
                    values={values}
                    intl={intl}
                    allStartHours={allStartHours}
                    allEndHours={allEndHours}
                    onRemoveDay={onRemoveDay}
                  />
                );
              })}
              {showRepeat && (
                <button
                  type="button"
                  className="bg-marketplaceColor text-sm py-2 font-semibold uppercase tracking-wide text-white border-solid shadow rounded max-w-xl border-marketplaceColor hover:bg-marketplaceColorDark hover:border-marketplaceColorDark transition duration-200 hover:cursor-pointer"
                  onClick={() => setShowRecurringModal(true)}
                >
                  Repeat
                </button>
              )}
              {RecurringModal}
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingAvailabilityPlanFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

EditListingAvailabilityPlanFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  fetchErrors: object.isRequired,

  listingTitle: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanForm = compose(injectIntl)(
  EditListingAvailabilityPlanFormComponent
);

EditListingAvailabilityPlanForm.displayName = 'EditListingAvailabilityPlanForm';

export default EditListingAvailabilityPlanForm;
