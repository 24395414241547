import React, { Component, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const RangeMoment = extendMoment(moment);

import css from './BookingDaysForm.module.css';

// const WEEKLY_PROBABILITY = 0.833;
// const MONTHLY_PROBABILITY = 0.833;
const WEEKLY_PROBABILITY = 1;
const MONTHLY_PROBABILITY = 1;

export class BookingDaysFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingData: null,
      error: null,
      showBreakdown: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    if (!e) return;

    const data = {
      ...e,
      startDate: this.state.bookingData.startDate,
      endDate: this.state.bookingData.endDate,
      variant: this.props.variant,
    };
    // this.props.onSubmit(e);
    this.props.onSubmit(data);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues, form) {
    const { timeSlots, variant } = this.props;
    this.setState({ error: null });
    const {
      bookingStartTime,
      bookingEndTime,
      weeks,
      months,
      bookingPeriodInterval,
    } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    let availabilityVariant = variant == 'weekly' ? weeks : months;

    if (availabilityVariant == undefined || availabilityVariant == 0) return;

    const hasFractions = String(availabilityVariant).indexOf('.') != -1;

    if (hasFractions) {
      return this.setState({
        error:
          variant == 'weekly'
            ? 'Fractions are not allowed for no. of weeks'
            : 'Fractions are not allowed for no. of months',
      });
    }

    availabilityVariant = parseInt(availabilityVariant);

    const differenceHours = moment
      .duration(moment(Number(bookingEndTime)).diff(moment(Number(bookingStartTime))))
      .asHours();

    if (!differenceHours) return;

    form.change('differenceInHours', differenceHours);

    let dayRange = [];
    let start = moment.tz(new Date(startDate).toUTCString(), this.props.timeZone);

    let count = availabilityVariant;
    let multiplier = variant == 'weekly' ? 7 : 30;

    // console.log({ availabilityVariant });
    while (count != 0) {
      Array.from({ length: multiplier }).forEach((_, index) => {
        dayRange.push({
          start: moment(start).valueOf(),
          end: moment(start)
            .add(differenceHours, 'hours')
            .valueOf(),
        });
        // dayRange.push(
        //   start
        //     .clone()
        //     .add(index == 0 ? 0 : 1, 'days')
        //     .format('DD-MM-YYYY')
        // );
        // console.log({ index, start, differenceHours });
        start = moment(start)
          .clone()
          .add(1, 'days')
          // .add(index == 0 ? 0 : 1, 'days')
          .valueOf();
      });
      count--;
    }

    // console.log(dayRange);

    // 4 days in 7 days, 25 days in 1 month

    let totalDays = [];

    for (const q of this.props.originalTimeSlots) {
      const {
        attributes: { start, end },
      } = q;

      const range = RangeMoment.range(
        `${moment(start).toISOString()}/${moment(end).toISOString()}`
      );
      totalDays.push(range);

      // const difference = Math.ceil(moment.duration(moment(end).subtract(moment(start))).asDays());

      // if (difference == 1) {
      //   console.log('1');
      //   totalDays.push(moment(start).format('DD-MM-YYYY'));
      // } else if (difference > 1) {
      //   let starter = moment(start)
      //     .startOf('day')
      //     .valueOf();
      //   let ender = moment(end)
      //     .endOf('day')
      //     .valueOf();

      //   while (starter <= ender) {
      //     totalDays.push(
      //       moment(starter)
      //         .clone()
      //         .format('DD-MM-YYYY')
      //     );
      //     starter = moment(starter)
      //       .clone()
      //       .add(1, 'days')
      //       .valueOf();
      //   }
      // }
    }

    totalDays = [...new Set(totalDays)];

    const ts = [
      ...new Set(
        this.props.originalTimeSlots?.map(t => moment(t?.attributes?.start).format('DD-MM-YYYY'))
      ),
    ];

    let present = 0;
    // let x = ts.forEach((item, index) => {
    //   dayRange.includes(item);
    // });

    // for (const t of ts) {
    // for (const t of totalDays) {
    // if (dayRange.includes(t)) {
    //   console.log(t);
    //   present += 1;
    // }
    // }

    for (const t of dayRange) {
      let i = 0;
      while (totalDays[i]) {
        const pointer = totalDays[i];
        if (pointer.contains(moment(t.start)) && pointer.contains(moment(t.end))) {
          present++;
        }
        // else {
        //   console.log(
        //     'else',
        //     { start: new Date(t.start).toISOString(), end: new Date(t.end).toISOString() },
        //     pointer
        //   );
        // }
        i++;
      }
    }

    // console.log(present);

    const probability = present / (variant == 'weekly' ? weeks * 7 : months * 30);

    console.log({ probability, present, variant, months });
    // if (variant == 'weekly' && probability < 0.5714)
    if (variant == 'weekly' && probability < WEEKLY_PROBABILITY)
      return this.setState({ error: 'Most of the days in the week(s) are not available.' });

    // if (variant == 'monthly' && probability < 0.833)
    if (variant == 'monthly' && probability < MONTHLY_PROBABILITY)
      return this.setState({ error: 'Most of the days in the month(s) are not available.' });

    const nextDate =
      variant == 'weekly'
        ? moment(startDate)
            .clone()
            .add(weeks, 'weeks')
        : moment(startDate)
            .clone()
            .add(months, 'months');

    // console.log(present);

    // console.log(dayRange, ts);

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    // console.log({ dayRange, t: this.props.originalTimeSlots });

    if (bookingStartTime && startDate && nextDate && !this.props.fetchLineItemsInProgress) {
      this.setState({
        bookingData: {
          startDate,
          endDate: nextDate.subtract(1, 'days').toISOString(),
          unitType: this.props.unitType,
          timeZone: this.props.timeZone,
        },
        showBreakdown: true,
      });

      let weeksMaybe = {};
      if (weeks) {
        weeksMaybe = { weeks };
      }
      let monthsMaybe = {};
      if (months) {
        monthsMaybe = { months };
      }

      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate: nextDate.toISOString(),
          variant,
          ...weeksMaybe,
          ...monthsMaybe,
        },
        listingId,
        isOwnListing,
      });
    }

    // if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
    //   this.props.onFetchTransactionLineItems({
    //     bookingData: { startDate, endDate },
    //     listingId,
    //     isOwnListing,
    //   });
    // }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        showBreakdown={this.state.showBreakdown}
        setShowBreakdown={state => this.setState({ showBreakdown: state })}
        bookingData={this.state.bookingData}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        rangeError={this.state.error}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            listing,
            variant,
            bookingData,
            rangeError,
            showBreakdown,
            setShowBreakdown,
            enqureOnly,
          } = fieldRenderProps;
          if (enqureOnly) return null;
          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          // const bookingData =
          //   startDate && endDate
          //     ? {
          //         unitType,
          //         startDate,
          //         endDate,
          //         timeZone,
          //       }
          //     : null;

          useEffect(() => {
            if (variant) {
              setShowBreakdown(false);
            }
          }, [variant]);

          const showEstimatedBreakdown =
            showBreakdown &&
            bookingData &&
            lineItems &&
            values?.[variant == 'weekly' ? 'weeks' : 'months'] > 0 &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError &&
            !!!rangeError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={{ ...bookingData, variant }}
                lineItems={lineItems}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const submitDisabled =
            !(
              values?.bookingStartTime &&
              values?.bookingEndTime &&
              values?.['bookingPeriodInterval'] &&
              values?.[variant == 'weekly' ? 'weeks' : 'months'] &&
              values?.[variant == 'weekly' ? 'weeks' : 'months'] > 0 &&
              values?.bookingStartDate?.date &&
              values?.bookingEndDate?.date
            ) ||
            fetchLineItemsInProgress ||
            !!rangeError;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values, form);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  rangeError={rangeError}
                  form={form}
                  duration={this.props.duration}
                  pristine={pristine}
                  listing={listing}
                  timeZone={timeZone}
                  variant={variant}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {this.props.duration ? (
                <React.Fragment>
                  {' '}
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                  <div className={submitButtonClasses}>
                    <PrimaryButton type="submit" disabled={submitDisabled}>
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </React.Fragment>
              ) : null}
            </Form>
          );
        }}
      />
    );
  }
}

BookingDaysFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDaysFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDaysForm = compose(injectIntl)(BookingDaysFormComponent);
BookingDaysForm.displayName = 'BookingDaysForm';

export default BookingDaysForm;
