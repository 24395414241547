import React, { useMemo, useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, PrimaryButton } from '../../components';
import { BookingDaysForm, BookingTimeForm } from '../../forms';
import { RadioButtonGroup } from 'react-rainbow-components';

import css from './BookingPanel.module.css';
import { SecondaryButton } from '../Button/Button';
import { withViewport } from '../../util/contextHelpers';
import _ from 'lodash';
// import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { fetchTimeSlots } from '../../containers/ListingPage/ListingPage.duck';
import BookingDatesForm from './BookingDatesForm';
import BookingDailyForm from './BookingDailyForm/BookingDailyForm';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    showBookingForm,
    showContactUser,
    onContactUser,
    viewport,
    fetchTimeSlotsError,
    timeSlots,
    onClearTransactionLineItems,
  } = props;
  const [duration, setDuration] = useState(undefined);
  const [constructedSlots, setConstructedSlots] = useState([]);

  const price = listing.attributes.price;
  const timeZone =
    config.appTimezone ||
    (listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone);
  const bookingOptions = listing?.attributes?.publicData?.pricing_options ?? [];
  const enqureOnly = bookingOptions?.includes('enquire_only');

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const enquireButtonText = intl.formatMessage({ id: 'BookingPanel.onContactUserButtonText' });

  const showDesktopEnquireButton = viewport.width > MODAL_BREAKPOINT;

  moment.tz.setDefault(config.appTimezone);

  const sortByLength = items => {
    const rank = {
      hourly: 1,
      daily: 2,
      weekly: 3,
      monthly: 4,
    };
    let newOrder = _.sortBy(items, function(element) {
      return rank[element];
    });

    return newOrder;
  };

  const getLabel = bookingType => {
    if (bookingType == 'hourly') return 'Hourly';
    if (bookingType == 'daily') return 'Daily';
    if (bookingType == 'weekly') return 'Weekly';
    if (bookingType == 'monthly') return 'Monthly';
  };

  const options = [...sortByLength(bookingOptions)].map(b => {
    return { value: b, label: getLabel(b) };
  });

  useEffect(() => {
    if (duration == undefined) setDuration(options?.[0]?.value);
  }, [options]);

  const handleOptionChange = event => {
    setDuration(event.target.value);
  };

  const enumerateDaysBetweenDates = function(startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate).startOf('day');

    let lastDate = moment(endDate).endOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }

    return dates;
  };

  useEffect(() => {
    if (timeSlots) {
      let newSlots = [];

      timeSlots?.forEach(ts => {
        const {
          attributes: { start, end },
        } = ts;
        const newStart =
          //  moment.tz(
          moment(new Date(start))
            .startOf('day')
            .subtract(1, 'days');
        // 'Asia/Calcutta'
        // );
        const newEnd =
          //  moment.tz(
          moment(new Date(end))
            .endOf('day')
            .add(1, 'days');
        // 'Asia/Calcutta'
        // );
        // .add(1, 'days');
        const data = enumerateDaysBetweenDates(newStart, newEnd);

        let index = 1;
        while (data[index]) {
          newSlots.push({
            attributes: {
              type: 'time-slot/day',
              // start: moment(data[index - 1]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
              // end: moment(data[index]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
              start: moment(data?.[index - 1]).toISOString(),
              end: moment(data?.[index]).toISOString(),
              seats: 1,
            },
          });

          index++;
        }
        setConstructedSlots(newSlots);
      });
    }
  }, [timeSlots]);

  const handleDailyBookingSubmit = values => {
    // console.log(values);
    const { bookingDates } = values;
    const { startDate, endDate } = bookingDates;
    if (!startDate || !endDate) return;

    onSubmit({
      startDate,
      endDate,
      variant: 'daily',
    });
  };

  const category = listing?.attributes?.publicData?.category;

  if (isOwnListing && category != config.LISTING) {
    return null;
  }

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalContentContainer}>
          <div className={css.modalHeading}>{/* <h1 className={css.title}>{title}</h1> */}</div>
          {/* {showContactUser && showDesktopEnquireButton && showBookingForm && (
          <div className={css.enquireButtonContainer}>
            <SecondaryButton onClick={onContactUser} enforcePagePreloadFor="SignupPage">
              {enquireButtonText}
            </SecondaryButton>
          </div>
        )} */}
          <div className={css.bookingPanelHeading}>
            {category == config.LISTING ? 'Book the Listing' : 'Contact Author'}
          </div>
          {showContactUser && showDesktopEnquireButton && (
            <div className={css.enquireButtonContainer}>
              {category != config.LISTING ? null : (
                <p className={css.smallPrint}>Contact the listing author</p>
              )}
              <PrimaryButton onClick={onContactUser} enforcePagePreloadFor="SignupPage">
                {enquireButtonText}
              </PrimaryButton>
            </div>
          )}
          {category != config.LISTING ? null : (
            <React.Fragment>
              {showBookingForm && (
                <div className={css.bookingHeading}>
                  {/* <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div> */}
                  <div className={css.bookingHeadingContainer}>
                    {/* <h2 className={titleClasses}>{title}</h2> */}
                    {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
                  </div>
                </div>
              )}
              {enqureOnly ? null : (
                <div className={css.buttonsContainer}>
                  <RadioButtonGroup
                    className={css.radioButtonContainer}
                    label="Select Duration"
                    id="duration"
                    name="duration"
                    options={options}
                    value={duration}
                    variant="brand"
                    onChange={handleOptionChange}
                  />
                </div>
              )}

              {showBookingTimeForm && showBookingForm ? (
                duration === 'daily' ? ( //   formId="BookingPanel" //   className={css.bookingForm} // <BookingDatesForm
                  //   submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                  //   unitType={unitType}
                  //   onSubmit={onSubmit}
                  //   price={price}
                  //   listingId={listing.id}
                  //   isOwnListing={isOwnListing}
                  //   timeSlots={timeSlots}
                  //   fetchTimeSlotsError={fetchTimeSlotsError}
                  //   onFetchTransactionLineItems={onFetchTransactionLineItems}
                  //   lineItems={lineItems}
                  //   fetchLineItemsInProgress={fetchLineItemsInProgress}
                  //   fetchLineItemsError={fetchLineItemsError}
                  //   duration={duration}
                  // />
                  // <DateRange
                  //   showMonthAndYearPickers={false}
                  //   showMonthArrow={true}
                  //   editableDateInputs={false}
                  //   onChange={item => setSelectedRange([item.selection])}
                  //   moveRangeOnFirstSelection={false}
                  //   // showMonthAndYearPickers={true}
                  //   ref={value => {
                  //     setRef(value);
                  //     // console.log(value);
                  //   }}
                  //   ranges={selectedRange}
                  //   disabledDay={day => {
                  //     const date = new Date(day);

                  //     if (timeSlots) {
                  //       const found = timeSlots.find(ts => {
                  //         const {
                  //           attributes: { start, end },
                  //         } = ts;
                  //         const startDate = moment(new Date(start));
                  //         const endDate = moment(new Date(end));
                  //         moment(date).isBetween(startDate, endDate);
                  //       });
                  //       if (found) return false;
                  //     }

                  //     return true;
                  //   }}
                  // />

                  // FIXME: FOR REFERENCE
                  // <BookingDatesForm
                  //   className={css.bookingForm}
                  //   formId="BookingPanel"
                  //   submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                  //   // unitType={unitType}
                  //   variant={duration}
                  //   unitType="line-item/day"
                  //   onSubmit={handleDailyBookingSubmit}
                  //   price={price}
                  //   listingId={listing.id}
                  //   isOwnListing={isOwnListing}
                  //   timeSlots={constructedSlots}
                  //   fetchTimeSlotsError={fetchTimeSlotsError}
                  //   onFetchTransactionLineItems={data => {
                  //     const { bookingData, ...rest } = data;
                  //     onFetchTransactionLineItems({
                  //       bookingData: { ...bookingData, type: 'daily' },
                  //       ...rest,
                  //     });
                  //     console.log(rest);
                  //   }}
                  //   lineItems={lineItems}
                  //   fetchLineItemsInProgress={fetchLineItemsInProgress}
                  //   fetchLineItemsError={fetchLineItemsError}
                  // />
                  <BookingDailyForm
                    className={css.bookingForm}
                    formId="BookingPanel"
                    submitButtonWrapperClassName={css.submitButtonWrapper}
                    unitType={unitType}
                    variant={duration}
                    onSubmit={params => {
                      onSubmit({ ...params, variant: 'daily' });
                    }}
                    price={price}
                    duration={duration}
                    listing={listing}
                    listingId={listing.id}
                    isOwnListing={isOwnListing}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    timeZone={timeZone}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    onClearTransactionLineItems={onClearTransactionLineItems}
                    enqureOnly={enqureOnly}
                  />
                ) : duration == 'weekly' ? (
                  <BookingDaysForm
                    className={css.bookingForm}
                    formId="BookingPanel"
                    submitButtonWrapperClassName={css.submitButtonWrapper}
                    unitType={unitType}
                    onSubmit={onSubmit}
                    variant={duration}
                    price={price}
                    duration={duration}
                    listing={listing}
                    timeSlots={constructedSlots}
                    originalTimeSlots={timeSlots}
                    listingId={listing.id}
                    isOwnListing={isOwnListing}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    timeZone={timeZone}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    enqureOnly={enqureOnly}
                  />
                ) : duration == 'monthly' ? (
                  <BookingDaysForm
                    className={css.bookingForm}
                    formId="BookingPanel"
                    submitButtonWrapperClassName={css.submitButtonWrapper}
                    unitType={unitType}
                    originalTimeSlots={timeSlots}
                    onSubmit={onSubmit}
                    price={price}
                    variant={duration}
                    duration={duration}
                    listing={listing}
                    timeSlots={constructedSlots}
                    listingId={listing.id}
                    isOwnListing={isOwnListing}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    timeZone={timeZone}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    enqureOnly={enqureOnly}
                  />
                ) : (
                  // null

                  <BookingTimeForm
                    className={css.bookingForm}
                    formId="BookingPanel"
                    submitButtonWrapperClassName={css.submitButtonWrapper}
                    unitType={unitType}
                    variant={duration}
                    onSubmit={params => {
                      console.log(params);
                      onSubmit({ ...params, variant: 'hourly' });
                    }}
                    price={price}
                    duration={duration}
                    listing={listing}
                    listingId={listing.id}
                    isOwnListing={isOwnListing}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                    timeZone={timeZone}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    onClearTransactionLineItems={onClearTransactionLineItems}
                    enqureOnly={enqureOnly}
                  />
                )
              ) : null}
            </React.Fragment>
          )}
        </div>
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {/* <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div> */}

        {showBookingTimeForm ? (
          <div className={css.actionButtonsContainer}>
            {showContactUser && (
              <div className={css.mobileEnquireContainer}>
                <SecondaryButton onClick={onContactUser} enforcePagePreloadFor="SignupPage">
                  {enquireButtonText}
                </SecondaryButton>
              </div>
            )}

            {showBookingForm && !enqureOnly && (
              <Button
                rootClassName={css.bookButton}
                onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
              >
                <FormattedMessage id="BookingPanel.ctaButtonMessage" />
              </Button>
            )}
          </div>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onMonthChange: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

export default compose(
  withRouter,
  injectIntl,
  withViewport,
  connect(null, mapDispatchToProps)
)(BookingPanel);
