import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingPitchandDealForm } from '../../forms';
import { ListingLink } from '..';

import css from './EditListingPitchandDealPanel.module.css';

const EditListingPitchandDealPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const [values, setValues] = useState(undefined);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPitchandDealPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPitchandDealPanel.createListingTitle" />
  );

  const {
    thedeal,
    theMarket,
    theBusiness,
    progress_Proof,
    objectives_Future,
    highlights,
    shortsummary,
  } = publicData;
  const initialValues = useMemo(() => {
    if (!values) {
      return {
        Keywords: publicData?.Keywords ? publicData?.Keywords : [],
        financials: publicData?.financials ? publicData?.financials : [],
        thedeal,
        theMarket,
        theBusiness,
        progress_Proof,
        objectives_Future,
        highlights,
        shortsummary,
      };
    }
    return { ...values };
  }, [values]);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPitchandDealForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            thedeal,
            theMarket,
            theBusiness,
            progress_Proof,
            objectives_Future,
            highlights,
            financials,
            shortsummary,
            Keywords,
          } = values;
          setValues(values);
          const isfinancials = financials ? { financials: financials } : { financials: null };
          const updatedValues = {
            publicData: {
              thedeal,
              theMarket,
              theBusiness,
              progress_Proof,
              objectives_Future,
              highlights,
              ...isfinancials,
              shortsummary,
              Keywords,
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingPitchandDealPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingPitchandDealPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPitchandDealPanel;
