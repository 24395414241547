import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import DayPicker, { DateUtils } from 'react-day-picker';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import moment from 'moment';

function MultiDaysPickerComponent({
  // input,
  days,
  setDays,
  form,
  formValues,
  onNextMonthClick,
  onPrevMonthClick,
  ...rest
}) {
  // const [days, setDays] = useState([]);
  const ref = useRef();

  // useEffect(() => {
  //   form.change('calendar', days);
  // }, [days]);
  return (
    <div>
      <DayPicker
        ref={ref}
        className="w-full"
        selectedDays={days}
        // selectedDays={input.value}
        // onDayClick={(day, calendarProps) => {
        //   if (calendarProps.disabled == true) return;
        //   if (Array.isArray(input.value) && input.value.length > 0) {
        //     // const previousValues = input.value;
        //     // const match = previousValues.filter(d => moment(d).isSame(moment(day)));
        //     // const isSame = match.length > 0 ? true : false;

        //     // input.onChange(
        //     //   isSame
        //     //     ? // ? previousValues
        //     //       previousValues.filter(p => !moment(p).isSame(moment(day)))
        //     //     : [...((Array.isArray(input.value) && input.value) || []), day]
        //     // );
        //     console.log({ day, calendarProps });

        //     input.onChange(
        //       calendarProps?.selected
        //         ? input.value.filter(d => !moment(d).isSame(moment(day)))
        //         : [...input.value, day]
        //     );
        //   } else {
        //     input.onChange([day]);
        //   }
        // }}
        onDayClick={(day, calendarProps) => {
          if (calendarProps.disabled == true) return;
          let newDays;
          if (days.length > 0) {
            // newDays =
            //   days.filter(d => moment(d).isSame(moment(day))).length > 0
            //     ? days.filter(d => !moment(d).isSame(moment(day)))
            //     : [...days, day];

            const sameDay = days.filter(d => moment(d).isSame(moment(day)));
            if (sameDay.length > 0) {
              newDays = [...days].filter(d => !moment(d).isSame(moment(day)));
              form.change(moment(sameDay[0]).format('DD-MM-YYYY'), undefined);
            } else {
              newDays = [...days, day];
            }

            setDays(newDays);
            // input.onChange(
            //   newDays
            // );
            // console.log('handled if');
          } else {
            newDays = [...days, day];
            setDays(newDays);
            // console.log('handled else');
            // form.change('calendar', newDays);

            // input.onChange(newDays);
          }
          // form.batch(() => {
          //   form.change('calendar', newDays);
          // });
        }}
        {...rest}
      />
      {ref && (
        <div className="absolute top-3 right-4 ">
          <button
            type="button"
            className="border-0 cursor-pointer inline-block rounded-full  w-7 h-7 mr-1"
            onClick={() => {
              onPrevMonthClick();
              ref.current.showPreviousMonth();
            }}
          >
            <FaChevronCircleLeft className="w-7 h-7 text-3xl font-bold text-marketplaceColor hover:text-marketplaceColorDark" />
          </button>
          <button
            type="button"
            className="border-0 cursor-pointer inline-block rounded-full  w-7 h-7"
            onClick={() => {
              onNextMonthClick();
              ref.current.showNextMonth();
            }}
          >
            <FaChevronCircleRight className="w-7 h-7 text-3xl font-bold text-marketplaceColor hover:text-marketplaceColorDark" />
          </button>
        </div>
      )}
    </div>
  );
}

export { MultiDaysPickerComponent };

export default function MultiDaysPicker(props) {
  return <MultiDaysPickerComponent {...props} />;
  // return <Field component={MultiDaysPickerComponent} {...props} />;
}
