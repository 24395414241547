import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { divideInSlots, extractTimestampFromValues, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import arrayMutators from 'final-form-arrays';

import css from './BookingTimeForm.module.css';
import moment from 'moment';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    console.log(e);
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    let { values } = formValues;
    values = { ...values, calendar: this.state.days };
    // console.log({ values });
    const { calendar, ...rest } = values;

    const filteredCalendar = calendar.filter(c => {
      const day = moment(c).format('DD-MM-YYYY');
      return typeof rest[day] != 'undefined';
    });
    values = { ...values, calendar: filteredCalendar };

    if (!values) return;
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    const validation =
      values &&
      values.calendar &&
      values.calendar.length > 0 &&
      values.calendar.every(c => {
        const label = moment(c).format('DD-MM-YYYY');
        return (
          values[label] &&
          values[label].length > 0 &&
          values[label].every(i => i.startTime && i.endTime)
        );
      });

    if (validation && !this.props.fetchLineItemsInProgress) {
      const monthlyTimeSlots = this.props.monthlyTimeSlots;

      let allTimes = [];
      const allTimeSlots = Object.entries(monthlyTimeSlots)
        .map(([key, value]) => {
          return value?.timeSlots ?? [];
        })
        .reduce((acc, item) => [...acc, ...item], []);

      for (let slot of allTimeSlots) {
        let newStart = moment(slot.attributes.start).valueOf();
        const end = moment(slot.attributes.end).valueOf();
        allTimes = [...allTimes, ...divideInSlots(newStart, end, 60)];
      }

      const allSlots =
        (values &&
          values?.calendar
            ?.map(c => {
              const label = moment(c).format('DD-MM-YYYY');
              return [
                {
                  [label]: allTimes.filter(a => a.startDay == label),
                },
              ];
            })
            ?.reduce((acc, item) => [...acc, ...item], [])) ||
        [];

      const submitValues = extractTimestampFromValues(allSlots, values);

      this.props.onFetchTransactionLineItems({
        bookingData: { data: submitValues, variant: 'hourly' },
        listingId,
        isOwnListing,
      });
    } else {
      // console.log('failed', validation);
      // this.props.onClearTransactionLineItems();
    }

    // if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
    //   this.props.onFetchTransactionLineItems({
    //     bookingData: { startDate, endDate, variant: 'hourly' },
    //     listingId,
    //     isOwnListing,
    //   });
    // }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    // console.log({ days: this.state.days });

    return (
      <FinalForm
        {...rest}
        mutators={{ ...arrayMutators }}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        days={this.state.days}
        setDays={days => this.setState({ days })}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            listing,
            days,
            setDays,
            enqureOnly,
          } = fieldRenderProps;
          if (enqureOnly) return null;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const submitDisabled = !(
            // values &&
            // values.calendar &&
            // values.calendar.length > 0 &&
            // values.calendar.every(c => {
            (
              this.state.days &&
              this.state.days.length > 0 &&
              this.state.days.every(c => {
                const label = moment(c).format('DD-MM-YYYY');
                return (
                  values[label] &&
                  values[label].length > 0 &&
                  values[label].every(i => i.startTime && i.endTime)
                );
              })
            )
          );

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            !submitDisabled && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={{
                  ...bookingData,
                  unitType,
                  variant: this.props.variant,
                }}
                lineItems={lineItems}
                hideDates
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };
          let allTimes = [];
          const allTimeSlots = Object.entries(monthlyTimeSlots)
            .map(([key, value]) => {
              return value?.timeSlots ?? [];
            })
            .reduce((acc, item) => [...acc, ...item], []);

          for (let slot of allTimeSlots) {
            let newStart = moment(slot.attributes.start).valueOf();
            const end = moment(slot.attributes.end).valueOf();
            allTimes = [...allTimes, ...divideInSlots(newStart, end, 60)];
          }

          const allSlots =
            this.state.days
              ?.map(c => {
                const label = moment(c).format('DD-MM-YYYY');
                return [
                  {
                    [label]: allTimes.filter(a => a.startDay == label),
                  },
                ];
              })
              ?.reduce((acc, item) => [...acc, ...item], []) || [];
          // (
          //   values &&
          //   values?.calendar
          //     ?.map(c => {
          //       const label = moment(c).format('DD-MM-YYYY');
          //       return [
          //         {
          //           [label]: allTimes.filter(a => a.startDay == label),
          //         },
          //       ];
          //     })
          //     ?.reduce((acc, item) => [...acc, ...item], [])) ||
          // [];

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  duration={this.props.duration}
                  pristine={pristine}
                  listing={listing}
                  timeZone={timeZone}
                  days={days}
                  setDays={setDays}
                  allSlots={allSlots}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {this.props.duration ? (
                <React.Fragment>
                  {' '}
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                  <div className={submitButtonClasses}>
                    <PrimaryButton
                      type="submit"
                      disabled={submitDisabled}
                      onClick={() => {
                        const submitValues = extractTimestampFromValues(allSlots, {
                          ...values,
                          calendar: this.state.days,
                        });
                        // return;
                        this.props.onSubmit(submitValues);
                      }}
                    >
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </React.Fragment>
              ) : null}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
