import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldAirbnbDateTimePicker,
  FieldTimeDatePicker,
  FieldDateTimeInput,
} from '../../components';

import css from './EditListingRequestedTimingForm.module.css';
import {
  composeValidators,
  fieldSelectModernMandatory,
  numberValidate,
  required,
} from '../../util/validators';

import arrayMutators from 'final-form-arrays';
import moment from 'moment';

export const EditListingRequestedTimingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        listingCategory,
        backButton,
      } = formRenderProps;
      const startDateMessage = intl.formatMessage({
        id: 'EditListingRequestedTimingForm.startDateLabel',
      });
      const startDateRequiredMessage = intl.formatMessage({
        id: 'EditListingRequestedTimingForm.startDateRequired',
      });
      const endDateMessage = intl.formatMessage({
        id: 'EditListingRequestedTimingForm.endDateLabel',
      });
      const startEndErrorMessage = intl.formatMessage({
        id: 'EditListingRequestedTimingForm.startEndErrorMessage',
      });
      const endDateRequiredMessage = intl.formatMessage({
        id: 'EditListingRequestedTimingForm.endDateRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingRequestedTimingForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingRequestedTimingForm.showListingFailed" />
        </p>
      ) : null;
      let isStartEndDateSelected = false;
      const timeDifferenceValidation = (startHour, endHour) => {
        if (startHour && endHour) {
          const startDateString = moment(new Date(startHour)).unix();
          // const startDateString = moment(`${now} ${startHour}`, 'DD-MM-Y HH:mm').unix();
          // const endDateString = moment(`${now} ${endHour}`, 'DD-MM-Y HH:mm').unix();
          const endDateString = moment(new Date(endHour)).unix();

          const difference = endDateString - startDateString;
          if (difference <= 0) {
            isStartEndDateSelected = true;
            return false;
          } else {
            return true;
          }
        }
        return true;
      };

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        !timeDifferenceValidation(values?.startDate, values?.endDate);
      // || !checkCorrectHour;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.dateTime}>
            <div className={css.level}>
              {/* <FieldDateTimeInput
                id="startDate"
                name="startDate"
                className={css.description}
                type="datetime-local"
                label={startDateMessage}
                validate={required(startDateRequiredMessage)}
              /> */}
              <FieldTimeDatePicker
                id="startDate"
                name="startDate"
                className={css.datePicker}
                minDate={`${new Date().getFullYear()},${new Date().getMonth() +
                  1},${new Date().getDate()}`}
                label={startDateMessage}
                placeholder="select start date and time "
                validate={required(startDateRequiredMessage)}
              />
            </div>
            <div className={css.level}>
              <FieldTimeDatePicker
                id="endDate"
                name="endDate"
                className={css.datePicker}
                minDate={`${new Date().getFullYear()},${new Date().getMonth() +
                  1},${new Date().getDate()}`}
                placeholder="Select end date and time "
                label={endDateMessage}
                validate={required(endDateRequiredMessage)}
              />
              {/* <FieldDateTimeInput
                id="endDate"
                name="endDate"
                className={css.description}
                type="datetime-local"
                label={endDateMessage}
                validate={required(endDateRequiredMessage)}
              /> */}
            </div>
          </div>
          {isStartEndDateSelected ? <p className={css.message}>{startEndErrorMessage}</p> : null}
          <div className={css.buttons}>
            {backButton}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingRequestedTimingFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingRequestedTimingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingRequestedTimingFormComponent);
