import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-sydney-australia',
    predictionPlace: {
      address: 'Sydney New South Wales, Australia',
      bounds: new LatLngBounds(
        new LatLng(-33.578140996, 151.343020992),
        new LatLng(-34.118344992, 150.520928608)
      ),
    },
  },

  {
    id: 'default-south-wales',
    predictionPlace: {
      address: 'New South Wales, Australia',
      bounds: new LatLngBounds(
        new LatLng(-28.08696659275, 159.208731278622),
        new LatLng(-37.5097267803825, 140.999474167179)
      ),
    },
  },
  {
    id: 'default-wes-Australia',
    predictionPlace: {
      address: 'Western Australia, Australia',
      bounds: new LatLngBounds(
        new LatLng(-13.5925142065245, 129.013019307777),
        new LatLng(-35.2164286984682, 112.821294400027)
      ),
    },
  },
  {
    id: 'default-queensland-Australia',
    predictionPlace: {
      address: 'Northern Territory, Australia',
      bounds: new LatLngBounds(
        new LatLng(-10.8680320010295, 138.063931682402),
        new LatLng(-25.9994829999899, 128.9794912)
      ),
    },
  },
];
export default defaultLocations;
