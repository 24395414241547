import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldCurrencyInput,
  FieldPhoneNumber,
  FieldSelectModern,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from '../../components';

import css from './EditListingCompanyInfoForm.module.css';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  fieldCheckBoxRequired,
  fieldSelectModernMandatory,
  phoneNumberValidate,
  required,
  urlValidate,
} from '../../util/validators';
import { compose } from 'redux';
const identity = v => v;
const EditListingCompanyInfoFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        values,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const urlValid = urlValidate('enter correct website');
      const { updateListingError, showListingsError } = fetchErrors || {};

      const priceRequired = required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const phoneNumberValid = phoneNumberValidate('Enter correct phone number');
      const priceValidators = priceRequired;
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCompanyInfoForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCompanyInfoForm.showListingFailed" />
        </p>
      ) : null;
      const titleMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.titleMessage',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.titleRequired',
      });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.titlePlaceholder',
      });
      const websiteMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.websiteMessage',
      });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.websitePlaceholder',
      });
      const websiteRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.websiteRequired',
      });
      const locationNotMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.locationNotRecognize',
      });
      const locationMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.locationMessage',
      });
      const locationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.locationPlaceholder',
      });
      const locationRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.locationRequired',
      });
      const phoneNumberMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.phoneNumberMessage',
      });
      const phoneNumberPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.phoneNumberPlaceholder',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.phoneNumberRequired',
      });
      const industryMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.industryMessage',
      });
      const industryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.industryPlaceholder',
      });
      const industryRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.industryRequired',
      });
      const stageMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.stageMessage',
      });
      const stagePlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.stagePlaceholder',
      });
      const stageRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.stageRequired',
      });
      const idealInvestorMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.idealInvestorMessage',
      });
      const idealInvestorPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.idealInvestorPlaceholder',
      });
      const idealInvestorRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.idealInvestorRequired',
      });
      const previousRoundMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.previousRoundMessage',
      });
      const previousRoundPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.previousRoundPlaceholder',
      });
      const previousRoundRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.previousRoundRequired',
      });
      const raisingAmountTotalMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisingAmountTotalMessage',
      });
      const raisingAmountTotalPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisingAmountTotalPlaceholder',
      });
      const raisingAmountTotalRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisingAmountTotalRequired',
      });
      const raisedAmountTotalMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisedAmountTotalMessage',
      });
      const raisedAmountTotalPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisedAmountTotalPlaceholder',
      });
      const raisedAmountTotalRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.raisedAmountTotalRequired',
      });
      const minimumInvestmentMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.minimumInvestmentMessage',
      });
      const minimumInvestmentPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.minimumInvestmentPlaceholder',
      });
      const minimumInvestmentRequiredMessage = intl.formatMessage({
        id: 'EditListingCompanyInfoForm.minimumInvestmentRequired',
      });
      // EditListingCompanyInfoForm.minimumInvestmentMessage
      // EditListingCompanyInfoForm.minimumInvestmentPlaceholder
      // EditListingCompanyInfoForm.minimumInvestmentRequired

      const options = config.custom.amenties;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldTextInput
            className={css.features}
            id="title"
            name="title"
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            validate={required(titleRequiredMessage)}
          />
          <FieldTextInput
            className={css.features}
            id="website"
            name="website"
            type="text"
            label={websiteMessage}
            placeholder={websitePlaceholderMessage}
            validate={urlValid}
          />
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            label={locationMessage}
            placeholder={locationPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(locationRequiredMessage),
              autocompletePlaceSelected(locationNotMessage)
            )}
          />
          <FieldPhoneNumber
            className={css.features}
            id="phoneNumber"
            name="phoneNumber"
            label={phoneNumberMessage}
            placeholder={phoneNumberPlaceholderMessage}
            validate={composeValidators(required(phoneNumberRequiredMessage), phoneNumberValid)}
          />
          <FieldSelectModern
            className={css.features}
            id="industry"
            name="industry"
            label={industryMessage}
            placeholder={industryPlaceholderMessage}
            isSearchable={false}
            isMulti
            options={config.custom.companyInfoIndustries}
            validate={fieldSelectModernMandatory(industryRequiredMessage)}
          />
          <FieldSelectModern
            className={css.features}
            id="stage"
            name="stage"
            isSearchable={false}
            label={stageMessage}
            placeholder={stagePlaceholderMessage}
            options={config.custom.companyInfoStage}
            validate={fieldSelectModernMandatory(stageRequiredMessage)}
          />
          <FieldSelectModern
            className={css.features}
            id="idealInvestor"
            name="idealInvestor"
            isSearchable={false}
            label={idealInvestorMessage}
            placeholder={idealInvestorPlaceholderMessage}
            options={config.custom.companyInfoIdeal}
            validate={fieldSelectModernMandatory(idealInvestorRequiredMessage)}
          />
          <FieldCurrencyInput
            id="previousRound"
            name="previousRound"
            className={css.features}
            label={previousRoundMessage}
            placeholder={previousRoundPlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(required(previousRoundRequiredMessage), priceValidators)}
            // validate={priceValidators}
          />
          <FieldCurrencyInput
            id="raisingAmountTotal"
            name="raisingAmountTotal"
            className={css.features}
            label={raisingAmountTotalMessage}
            placeholder={raisingAmountTotalPlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(
              required(raisingAmountTotalRequiredMessage),
              priceValidators
            )}
          />
          <FieldCurrencyInput
            id="raisedAmountTotal"
            name="raisedAmountTotal"
            className={css.features}
            label={raisedAmountTotalMessage}
            placeholder={raisedAmountTotalPlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(
              required(raisedAmountTotalRequiredMessage),
              priceValidators
            )}
          />
          <FieldCurrencyInput
            id="minimumInvestment"
            name="minimumInvestment"
            className={css.features}
            label={minimumInvestmentMessage}
            placeholder={minimumInvestmentPlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={composeValidators(
              required(minimumInvestmentRequiredMessage),
              priceValidators
            )}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCompanyInfoFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingCompanyInfoFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingCompanyInfoForm = EditListingCompanyInfoFormComponent;

export default compose(injectIntl)(EditListingCompanyInfoForm);
