import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldTextInput,
  FieldVideoUpload,
  NamedLink,
  NamedRedirect,
} from '../../components';

import css from './EditListingVideosForm.module.css';
import { composeValidators, required } from '../../util/validators';
import { IoArrowRedoOutline } from 'react-icons/io5';
export const EditListingVideosFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        currentListing,
        backButton,
        id,
        editListingLinkType,
        slug,
      } = formRenderProps;
      const [skip, setSkip] = useState(false);
      const handleSkip = async e => {
        e.preventDefault();
        await setSkip(true);
        await handleSubmit();
      };
      const skipButton =
        editListingLinkType !== 'edit' ? (
          <NamedLink
            className={css.skipButtonContainer}
            name="EditListingPage"
            params={{
              id,
              slug,
              type: editListingLinkType,
              tab: 'photos',
            }}
          >
            <button className={css.skipButton} type="button" onClick={handleSkip}>
              {/* <IoArrowRedoOutline className={css.skipButtonIcon} /> */}
              Skip
            </button>
          </NamedLink>
        ) : null;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingVideosForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingVideosForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const currentListingId = currentListing.id.uuid;

      return (
        <>
          {skipButton}
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}

            <FieldVideoUpload
              parentValues={values}
              urlParam={currentListingId}
              parentForm={form}
              publicDataHint="videos"
            />
            <div className={css.buttons}>
              {backButton}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={skip ? null : submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        </>
      );
    }}
  />
);

EditListingVideosFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingVideosFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingVideosFormComponent);
