import { RadioButtonGroup } from 'react-rainbow-components';
import React from 'react';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';

function FieldRadioButtonGroupComponent(props) {
  const {
    name,
    label,
    id,
    variant = 'brand',
    meta,
    input,
    customErrorText,
    options,
    ...rest
  } = props;
  console.log(input);
  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  console.log(rest);
  return (
    <div>
      <RadioButtonGroup
        id={id}
        options={options}
        variant={variant}
        label={label}
        options={options}
        {...rest}
        {...input}
      />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
}

function FieldRadioButtonGroup(props) {
  return <Field component={FieldRadioButtonGroupComponent} {...props} />;
}

export default FieldRadioButtonGroup;
