import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldTextInput, Form, FieldTagInput } from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import css from './EditListingPitchandDealForm.module.css';
import {
  composeValidators,
  fieldCheckBoxRequired,
  required,
  requiredArrayItems,
} from '../../util/validators';
import { compose } from 'redux';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditListingPitchandDealFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        form: {
          mutators: { push, pop },
        },
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        values,
        form,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;

      const financialsCheck = !values?.financials?.every(f => f.year && f.turnOver && f.profit);

      const finanicalsValidation = Array.isArray(values?.financials) ? financialsCheck : false;

      const submitDisabled = invalid || disabled || submitInProgress || finanicalsValidation;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPitchandDealForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPitchandDealForm.showListingFailed" />
        </p>
      ) : null;
      const otherAmenityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.otherAmenityPlaceholder',
      });
      const otherAmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.otherAmenityRequired',
      });
      const AmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.AmenityRequired',
      });

      console.log(values);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldTextInput
            id="Shortsummary"
            name="shortsummary"
            className={css.description}
            type="textarea"
            label="Short summary"
            placeholder="Please give a short summary about your business"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="theBusiness"
            name="theBusiness"
            className={css.description}
            type="textarea"
            label="The Business"
            placeholder="Please tells us about your business"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="theMarket"
            name="theMarket"
            className={css.description}
            type="textarea"
            label="The Market"
            placeholder="Please tells us about Market"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="pogress_Proof"
            name="progress_Proof"
            className={css.description}
            type="textarea"
            label="Progress/Proof"
            placeholder="Please give a details Progress/ Proof "
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="objectives_Future"
            name="objectives_Future"
            className={css.description}
            type="textarea"
            label="Objectives/Future"
            placeholder="Please give a details about Objectives / Future"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="highlights"
            name="highlights"
            className={css.description}
            type="textarea"
            label="Highlights"
            placeholder="Please give us some  highlights about your business"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <FieldTextInput
            id="thedeal"
            name="thedeal"
            className={css.description}
            type="textarea"
            label="The deal"
            placeholder="Please give us some  highlights about your business"
            validate={composeValidators(required('This field is mandatory'))}
          />
          <div>
            Financials
            <p class={css.text}>Financials (Previous and Projected) (Not required)</p>
            <FieldArray name="financials">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div className={css.farry_contain} key={name + 'a'}>
                    <div className={css.farry} key={name}>
                      <div className={css.test}>
                        <label>Years</label>
                        <Field
                          className={css.year}
                          name={`${name}.year`}
                          component="input"
                          type="number"
                          //placeholder="Years"
                        />
                      </div>
                      <div className={css.test}>
                        <label>Turnover</label>
                        <div className={css.inlineField}>
                          {' '}
                          <div className={css.fspan}>A$</div>
                          <Field
                            className={classNames(css.finput, 'px-3')}
                            name={`${name}.turnOver`}
                            component="input"
                            type="number"
                            //placeholder="Turnover"
                          />
                        </div>
                      </div>

                      <div className={css.farry}>
                        <div className={css.test}>
                          <label>Profit</label>
                          <div className={css.inlineField}>
                            <div className={css.fspan}>A$</div>
                            <Field
                              className={classNames(css.finput, 'px-3')}
                              name={`${name}.profit`}
                              component="input"
                              type="number"
                              // placeholder="Profit"
                            />
                          </div>
                        </div>

                        <div
                          onClick={() => fields.remove(index)}
                          className="cursor-pointer mt-5 ml-4"
                        >
                          <AiFillCloseCircle className="text-2xl text-red-500" />
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                ))
              }
            </FieldArray>
            <div className={css.btns}>
              <button
                className={css.btn}
                type="button"
                onClick={() =>
                  push('financials', {
                    year: '',
                    turnOver: '',
                    profit: '',
                  })
                }
              >
                {values?.financials?.length > 0 ? 'Add Another Year' : 'Add financials'}
              </button>
              {/* <button className={css.btn} type="button" onClick={() => console.log('hide')}>
                Hide Financials
              </button> */}
            </div>
          </div>

          <div className={css.title}>
            <label className={css.label}>Add keywords (as many):</label>
            <FieldTagInput
              id="Keywords"
              name="Keywords"
              onlyUnique
              inputProps={{
                placeholder: 'Law, education',
              }}
              validate={requiredArrayItems(' keywords is required.')}
            />
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPitchandDealFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingPitchandDealFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingPitchandDealForm = EditListingPitchandDealFormComponent;

export default compose(injectIntl)(EditListingPitchandDealForm);
