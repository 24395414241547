import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingCategory,
    id,
    slug,
    editListingLinkType,
    backButton,
  } = props;

  const [newInitials, setNewInitials] = React.useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  let panelTitle;
  if (listingCategory === config.REQUIREMENT) {
    panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPricingPanel.requirementListingtitle"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPricingPanel.createRequirementListingTitle" />
    );
  } else {
    panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPricingPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );
  }

  let initValues;

  if (publicData?.category == config.REQUIREMENT) {
    initValues = { price };
  } else {
    initValues = {
      price: publicData?.hourly_price
        ? new Money(publicData?.hourly_price, config.currency)
        : undefined,
      pricing_options: publicData?.pricing_options,
      daily_price: publicData.daily_price
        ? new Money(publicData.daily_price, config.currency)
        : undefined,
      weekly_price: publicData.weekly_price
        ? new Money(publicData.weekly_price, config.currency)
        : undefined,
      monthly_price: publicData.monthly_price
        ? new Money(publicData.monthly_price, config.currency)
        : undefined,
      enquire_only: publicData.enquire_only,
      minimum_spend: publicData.minimum_spend
        ? new Money(publicData.minimum_spend, config.currency)
        : undefined,
    };
  }

  const setNewInitialValues = values => {
    const { daily_price, monthly_price, weekly_price, price, pricing_options } = values;
    // console.log(hourly_price, values);
    const data = {
      price: price ?? undefined,
      pricing_options: pricing_options,
      daily_price: daily_price ?? undefined,
      weekly_price: weekly_price ?? undefined,
      monthly_price: monthly_price ?? undefined,
    };

    setNewInitials(data);
  };

  const initialValues = useMemo(() => {
    if (newInitials) {
      return { ...initValues, ...newInitials };
    }

    if (publicData?.category == config.REQUIREMENT) return { price };
    else return initValues;
  }, [newInitials]);

  const getPublicDataValues = values => {
    let pubData = {};
    const {
      price: hourly_price,
      daily_price,
      weekly_price,
      monthly_price,
      pricing_options,
      enquire_only,
    } = values;
    if (pricing_options?.includes('enquire_only'))
      return {
        ...pubData,
        monthly_price: 0,
        hourly_price: null,
        daily_price: null,
        weekly_price: null,
        pricing_options: ['enquire_only'],
      };
    if (pricing_options.includes('hourly') && hourly_price)
      pubData = { hourly_price: hourly_price.amount };
    else pubData = { ...pubData, hourly_price: null };
    if (pricing_options.includes('weekly') && weekly_price)
      pubData = { ...pubData, weekly_price: weekly_price.amount };
    else pubData = { ...pubData, weekly_price: null };

    if (pricing_options.includes('daily') && daily_price)
      pubData = { ...pubData, daily_price: daily_price.amount };
    else pubData = { ...pubData, daily_price: null };

    if (pricing_options.includes('monthly') && monthly_price)
      pubData = { ...pubData, monthly_price: monthly_price.amount };
    else pubData = { ...pubData, monthly_price: null };

    pubData = { ...pubData, pricing_options };
    return pubData;
  };

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const category = publicData.category;
        const { minimum_spend, pricing_options } = values;
        const enquire_only = pricing_options?.includes('enquire_only');
        let updateValues = {};
        if (category == config.REQUIREMENT) updateValues = { price: values?.price };
        else {
          updateValues = {
            price: new Money(100, config.currency),
            publicData: {
              ...getPublicDataValues(values),
              enquire_only,
              minimum_spend: enquire_only ? null : minimum_spend?.amount,
            },
          };

          setNewInitialValues(values);
        }

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      category={publicData.category}
      id={id}
      slug={slug}
      editListingLinkType={editListingLinkType}
      backButton={backButton}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
