import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
// import { IoArrowBackCircleOutline } from 'react-icons/io';
import { IoArrowBackCircleOutline } from 'react-icons/io5';

import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelectModern,
  FieldTimePicker,
  SecondaryButton,
  IconAdd,
  NamedLink,
} from '../../components';

import css from './EditListingPoliciesForm.module.css';
import {
  composeValidators,
  fieldSelectModernMandatory,
  numberValidate,
  required,
} from '../../util/validators';
import moment from 'moment';
import config from '../../config';
import arrayMutators from 'final-form-arrays';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        spaceTypes,
        usageTypes,
        lengthTypes,
        values,
        initialValues,
        listingCategory,
        id,
        slug,
        editListingLinkType,
        backButton,
      } = formRenderProps;
      const numberWithoutFractions = numberValidate(
        'Fractions like 1.5, 3.2, 4.5 etc are not allowed'
      );
      const typeLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.typeLabel',
      });
      const typePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.typePlaceholder',
      });
      const typeRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.typeRequired',
      });

      const sizeLabelMessage = intl.formatMessage(
        {
          id: 'EditListingPoliciesForm.sizeLabel',
        },
        {
          ps: (
            <span>
              m<sup>2</sup>
            </span>
          ),
        }
      );

      const sizePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.sizePlaceholder',
      });
      const sizeRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.sizeRequired',
      });

      const capacityLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.capacityLabel',
      });
      const capacityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.capacityPlaceholder',
      });
      const capacityRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.capacityRequired',
      });
      const usageLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.usageLabel',
      });
      const usagePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.usagePlaceholder',
      });
      const usageRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.usageRequired',
      });
      const otherUsagePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.otherUsagePlaceholder',
      });
      const otherUsageRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.otherUsageRequired',
      });
      const lengthLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.lengthLabel',
      });
      const lengthPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.lengthPlaceholder',
      });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.lengthRequired',
      });
      const openingHoursMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.openingHourMessage',
      });
      const addOpeningHourMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.addOpeningHourMessage',
      });
      const openingErrorMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.openingHourErrorMessage',
      });
      const startHourRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.startHourRequiredMessage',
      });
      const endHourRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.endHourRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;
      const timeDifferenceValidation = (startHour, endHour) => {
        if (startHour && endHour) {
          const now = moment(new Date()).format('DD-MM-Y');
          const startDateString = moment(`${now} ${startHour}`, 'DD-MM-Y HH:mm').unix();
          const endDateString = moment(`${now} ${endHour}`, 'DD-MM-Y HH:mm').unix();

          const difference = endDateString - startDateString;
          if (difference <= 0) {
            return openingErrorMessage;
          } else {
            return null;
          }
        }
        return undefined;
      };

      const isOpeningTimesValid = values?.openingHours?.some(({ startHour, endHour }) =>
        timeDifferenceValidation(startHour, endHour) == null ? false : true
      );
      const isOpeningHourSelected =
        listingCategory === config.REQUIREMENT ? false : !values?.openingHours?.length > 0;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      // ||
      //  || isOpeningTimesValid
      // isOpeningHourSelected;
      // console.log(
      //   'values',
      //   values?.spaceType?.find(i => i.key === 'other')
      // );
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldSelectModern
            className={css.description}
            id="spaceType"
            name="spaceType"
            // isSearchable={false}
            options={spaceTypes}
            label={typeLabelMessage}
            placeholder={typePlaceholderMessage}
            validate={fieldSelectModernMandatory(typeRequiredMessage)}
            isMulti
          />
          {values?.spaceType?.find(i => i.key === 'other') ? (
            <FieldTextInput
              className={css.description}
              id="otherSpaceType"
              name="otherSpaceType"
              type="text"
              label={intl.formatMessage({
                id: 'EditListingPoliciesForm.EditListingPoliciesForm.otherSpaceTypeLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingPoliciesForm.EditListingPoliciesForm.otherSpaceTypePlaceholder',
              })}
              validate={composeValidators(
                required(
                  <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.otherSpaceTypeRequiredMessage" />
                )
              )}
            />
          ) : null}

          <FieldTextInput
            id="sizeOfSpace"
            name="sizeOfSpace"
            className={css.description}
            type="number"
            label={sizeLabelMessage}
            placeholder={sizePlaceholderMessage}
            validate={composeValidators(numberWithoutFractions)}
          />

          <FieldTextInput
            id="capacityOfSpace"
            name="capacityOfSpace"
            className={css.description}
            type="number"
            label={capacityLabelMessage}
            placeholder={capacityPlaceholderMessage}
            // validate={composeValidators(required(capacityRequiredMessage), numberWithoutFractions)}
          />
          {/* {listingCategory === config.REQUIREMENT ? null : (
            <div>
              <p>{openingHoursMessage}</p>

              <FieldArray name="openingHours">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <div className={css.FieldArray}>
                          {' '}
                          <FieldTimePicker
                            className={css.level}
                            id={`${name}.startHour`}
                            name={`${name}.startHour`}
                            label="Start hour"
                            validate={composeValidators(required(startHourRequiredMessage))}
                          />
                          <FieldTimePicker
                            className={css.level}
                            id={`${name}.endHour`}
                            name={`${name}.endHour`}
                            label="End hour"
                            validate={composeValidators(required(endHourRequiredMessage))}
                          />
                          <div>
                            <span className={css.close} onClick={() => fields.remove(index)}>
                              &times;
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <br />

                    <SecondaryButton
                      className={css.addButton}
                      type="button"
                      onClick={() => {
                        fields.push({ startHour: '', endHour: '' });
                      }}
                    >
                      <IconAdd />
                      <span style={{ paddingLeft: '10px' }}>{addOpeningHourMessage}</span>
                    </SecondaryButton>
                  </div>
                )}
              </FieldArray>
            </div>
          )} */}

          <FieldSelectModern
            className={css.description}
            id="length"
            name="length"
            options={lengthTypes}
            // isSearchable={false}
            label={lengthLabelMessage}
            placeholder={lengthPlaceholderMessage}
            validate={fieldSelectModernMandatory(lengthRequiredMessage)}
          />
          <FieldSelectModern
            className={css.description}
            id="usage"
            name="usage"
            options={usageTypes}
            // isSearchable={false}
            label={usageLabelMessage}
            placeholder={usagePlaceholderMessage}
            validate={fieldSelectModernMandatory(usageRequiredMessage)}
            isMulti
          />
          {values?.usage?.find(i => i.key == 'other') ? (
            <FieldTextInput
              id="otherUsage"
              name="otherUsage"
              className={css.title}
              type="text"
              placeholder={otherUsagePlaceholderMessage}
              validate={composeValidators(required(otherUsageRequiredMessage))}
            />
          ) : null}

          <div className={css.buttons}>
            {backButton}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
