import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldTextInput,
  Form,
  FieldUpload,
  FieldLogodUpload,
  FieldFileUpload,
  IconSpinner,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import css from './EditListingLogoForm.module.css';
import {
  composeValidators,
  fieldCheckBoxRequired,
  required,
  requiredArrayItems,
} from '../../util/validators';
import { compose } from 'redux';
import { IoIosAddCircle } from 'react-icons/io';
import axios from 'axios';

const EditListingLogoFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        form: {
          mutators: { push, pop },
        },
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        values,
        form,
        currentListingId,
        uploadFile,
        uploadInProgress,
        uploadError,
      } = formRenderProps;
      // console.log(values);
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid || disabled || submitInProgress || !values?.logo || typeof values?.logo != 'string';

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLogoForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLogoForm.showListingFailed" />
        </p>
      ) : null;
      // const otherAmenityPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.otherAmenityPlaceholder',
      // });
      // const otherAmenityRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.otherAmenityRequired',
      // });
      // const AmenityRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.AmenityRequired',
      // });

      // console.log(values);

      const imgSrc = values?.logo
        ? typeof values?.logo == 'string'
          ? values?.logo
          : undefined
        : undefined;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className="px-16 lg:px-0">
            <FieldFileUpload
              name="logo"
              id="logo"
              label={
                <div className="border-2 border-dashed border-yellow-400 rounded-full hover:border-yellow-500 group bg-gray-50 hover:bg-gray-100 transition duration-100 w-64 h-64 flex items-center justify-center">
                  {imgSrc ? (
                    <img src={imgSrc} className="w-64 h-64 object-cover block rounded-full" />
                  ) : (
                    <div className="flex items-center flex-col">
                      {uploadInProgress ? (
                        <IconSpinner />
                      ) : (
                        <IoIosAddCircle className="text-yellow-300 text-6xl group-hover:text-yellow-400" />
                      )}
                    </div>
                  )}
                </div>
              }
              center={false}
              showUploading={false}
              acceptFiles="images"
              uploading={uploadInProgress}
              onSelectFile={file => uploadFile(file, form)}
            />
            {uploadError && (
              <p className="text-red-500 text-sm font-semibold text-center">{uploadError}</p>
            )}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLogoFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLogoFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingLogoForm = EditListingLogoFormComponent;

export default compose(injectIntl)(EditListingLogoForm);
