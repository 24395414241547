import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        Please read these terms and conditions before using www.spotspace.com.au (<b>Website</b>) or
        any app related to the Website (<b>App</b>). By accessing or using the Website or App, you
        are deemed to have read the terms and conditions and be legally bound by them, including any
        amendment to them.
      </p>

      <h4>1. DEFINITIONS</h4>
      <p>In these terms and conditions:</p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}> 1.1.</p>{' '}
        <p className={css.listItem}>
          <b>"Communications"</b> means your communications made through the Website and, where the
          context permits, includes Information;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.2.</p>
        <p className={css.listItem}>
          {' '}
          <b>"Information"</b> means any information or material that you post, transmit or upload
          to, or by using, the Website, including any links or diversions to other websites or apps;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.3.</p>
        <p className={css.listItem}>
          <b>“Terms”</b> means these terms and conditions and includes changes made to the Terms
          from time to time;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.4.</p>
        <p className={css.listItem}>
          <b>“we”, “us”,</b> and <b>“our” </b>refer to Spotspace and where the context permits,
          includes our officers,employees, contractors and agents and our successors;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.5.</p>
        <p className={css.listItem}>
          a reference to the <b>Website</b> includes any App related to the Website;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.6.</p>
        <p className={css.listItem}>
          <b>"you"</b>, where the context permits, includes your officers, employees, contractors
          and agents and your successors;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.7.</p>
        <p className={css.listItem}>
          a reference to a term and/or condition means a term and/or condition in these Terms;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.8.</p>
        <p className={css.listItem}>
          terms such as<b> “including”, "includes", "such as"</b> and <b> “for example”</b> mean
          that what is stated after these terms is not limited and may include other things and
          other possible examples;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.9.</p>
        <p className={css.listItem}>
          a reference to any document, law, rules, body or thing includes any change to it,
          replacement of it or successors to it, and in relation to laws, includes any rules,
          regulations, orders or notices made in relation to the law;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.10.</p>
        <p className={css.listItem}>
          words importing persons include corporations and other entities and vice versa, and also
          include authorities;
        </p>
      </p>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>1.11.</p>
        <p className={css.listItem}>
          where a person is prohibited from doing something, that person must not allow, encourage,
          solicit or facilitate the doing of that thing and where a person is obliged to do
          something, this includes an obligation to ensure that the obligation is satisfied by the
          person or any other relevant person.
        </p>
      </p>
      <p />
      <h4 className={css.listItemContainer}>
        <h4 className={css.listIndex}>2.</h4>
        <h4 className={css.listItem}>INTRODUCTION and INFORMATION</h4>
      </h4>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>2.1.</p>
        <p className={css.listItem}>
          The Website has been developed for use by people (<b>Posters</b>) wishing to post on the
          Website available property space (<b>"Areas"</b>) to licence or lease and for use by
          people looking for such available Areas (<b>Occupiers</b>).
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>2.2.</p>
        <p className={css.listItem}>
          The Website contains information, representations, warranties and offers about Areas and
          Posters, including links, images, diversions and references to information and sources
          provided by third parties <b>(Information)</b>. You acknowledge that we do not monitor,
          check or endorse the Information, nor make any representations or warranties in relation
          to Information.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>2.3.</p>
        <p className={css.listItem}>You acknowledge and agree that:</p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>the Information is not advice, professional or expert,</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>
            the Information may not be accurate, complete, current, truthful, without error, without
            virus or other software threat, or without misrepresentation;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>you use the Information at your own risk;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(d)</p>
          <p className={css.listItem}>
            you are responsible for making your own assessment regarding the Information, including
            its accuracy, sufficiency, truthfulness and completeness;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(e)</p>
          <p className={css.listItem}>
            you are responsible for making your own assessment regarding any use you make of the
            Information;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(f)</p>
          <p className={css.listItem}>you must comply with all relevant laws.</p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>2.4.</p>
        <p className={css.listItem}>
          You agree not to access or use the Website or Information in any way, or do anything, that
          causes or may cause:
        </p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>
            the Website to be copied, damaged, interrupted, or impaired, including mirroring or
            framing any part or page of the Website;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>
            from the use or access to the Website and Information, the creation of any other
            website, publication, app or searchable database;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>
            from the use or access to the Website and Information, an automated process to query,
            retrieve, data-mine, copy, scrape, access any Information or create any index or
            database based on the Information;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(d)</p>
          <p className={css.listItem}>
            annoyance, nuisance, inconvenience or mischief, including anything that would cause, in
            our sole discretion, excessive traffic or demands on our or other systems;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(e)</p>
          <p className={css.listItem}>
            sale, licensing, display, modification, transmission, transfer, reproduction, adaption,
            distribution, translation, creation of derivative works, or publishing of any
            Information or functionality of the Website, including to create a competing database,
            publication, website, app or searchable database or to exploit our Website;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(f)</p>
          <p className={css.listItem}>
            fraud, nor access or use the Website or Information for fraudulent purposes, or in
            connection with unlawful or criminal activity,
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(g)</p>
          <p className={css.listItem}>
            breach of copyright, trademarks, confidentiality, privacy or any other right, whether
            ours or of any person;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(h)</p>
          <p className={css.listItem}>
            an unreasonable use or access, as determined in our sole discretion, including
            soliciting money, passwords and personal information which is not for the intended
            purpose of the Website; or
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(i)</p>
          <p className={css.listItem}>breach these Terms or any laws.</p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3</p>
        <p className={css.listItem}>
          We reserve the right to make changes, at any time without liability to you, to the Website
          or these Terms at any time. Your use and continued use of the Website is deemed your
          agreement to be bound by the Terms, as amended, and to have accepted all changes.
        </p>
      </p>

      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>4</p>
        <p className={css.listItem}>
          We can terminate or restrict your access and use of the Website or the Information at any
          time.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5</p>
        <p className={css.listItem}>
          You acknowledge that all designs, copyright, patents and trademarks (unregistered or
          registered) relating to the Website (<b>Intellectual Property Rights</b>) are owned by us
          or by a third party and licensed to us.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6</p>
        <p className={css.listItem}>
          Nothing on the Website grants any right in relation to the Intellectual Property Rights.
          You cannot use, copy or breach the Intellectual Property Rights, other than, in relation
          to copyright, for personal, research or private purposes and as is considered fair use
          under the Copyright Act 1968 (Cth).
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>7</p>
        <p className={css.listItem}>
          You must keep your username and password, in relation to any password restricted access
          the Website, confidential and safe from unauthorised use. You are liable for unauthorised
          use.
        </p>
      </p>
      <p />
      <h4 className={css.listItemContainer}>
        <h4 className={css.listIndex}>3.</h4>
        <h4 className={css.listItem}>POSTERS AND OCCUPIERS</h4>
      </h4>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3.1</p>
        <p className={css.listItem}>
          Any contact between Posters and Occupiers is between them and is not reviewed nor
          regulated by us. We are not liable for the contact, including what is communicated during
          the contact.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3.2</p>
        <p className={css.listItem}>
          Any contract entered between Posters and Occupiers is between them and is not reviewed nor
          regulated by us. We are not a party to that contract and we are not liable for the terms
          of that contract. Any issue relating to such contract is between the Posters and
          Occupiers, and we must not be joined in any action relating to such contract.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3.3.</p>
        <p className={css.listItem}>Posters and Occupiers</p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>use the Information and other information on the Website;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>make contact with each other; and</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>enter into contracts with each other;</p>
        </p>
        <p>
          at their own risk, relying on their own due diligence including obtaining their own legal,
          financial and other professional advice in relation to anything related to or arising from
          the use of, or access to, the Website, the Information, and contact and contracts between
          a Poster and Occupier.
        </p>
      </div>
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3.4.</p>
        <p className={css.listItem}>
          Occupiers must rely on their own due diligence including obtain their own legal and
          financial advice in relation to any Area, contract or thing offered by a Poster.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>3.5.</p>
        <p className={css.listItem}>
          We are not responsible for resolving any dispute between a Poster and Occupier.
        </p>
      </p>
      <p />
      <h4 className={css.listItemContainer}>
        <h4 className={css.listIndex}>4.</h4>
        <h4 className={css.listItem}>SOFTWARE, WEBSITES AND APPS</h4>
      </h4>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>4.1.</p>
        <p className={css.listItem}>
          If we make available any software or app for download, you must not:
        </p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>use it other than for its intended purpose;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>
            do anything which may interfere with its operation or its effective operation; nor
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>
            copy, modify, reproduce, reverse engineer or compile any part or all of it.
          </p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>4.2.</p>
        <p className={css.listItem}>
          You acknowledge that if there is a link or diversion to another website or app in
          Communications or within the Website, that those websites or apps may be owned or
          administered by third parties. We are not liable for any content of third party websites
          or apps nor for anything arising from the link or diversion to those websites or apps,
          including access to or using them.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>4.3.</p>
        <p className={css.listItem}>
          If you link to our Website or information in our Website, you must:
        </p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>
            attribute the Website as being our Website and not your, or any other person's, website
            or app;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>not attribute a link but then link to another website;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>
            not link in a manner which may or does compete with our Website;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(d)</p>
          <p className={css.listItem}>
            not attribute information from our Website as being your, or any other person's,
            information, unless it is your Information.
          </p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.</p>
        <p className={css.listItem}>COMMUNICATIONS AND INFORMATION</p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.1.</p>
        <p className={css.listItem}>
          In consideration of us allowing you use and access to the Website, you grant to us a
          non-exclusive, royalty-free, world-wide, irrevocable, transferable and perpetual licence
          to publish, delete, change, copy, distribute, display, create derivative works, perform
          and sub-licence Information (<b>IP Licence</b>) and you waive your moral rights to
          Information. You also grant each user of our Website an IP Licence.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.2.</p>
        <p className={css.listItem}>
          You warrant that you have all necessary rights to upload, post and transmit Information
          and to allow the IP Licence to exist. You also warrant that your actions and agreements in
          relation to Information will not breach any third party's rights.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.3.</p>
        <p className={css.listItem}>
          If we make available the ability for you to communicate through the Website including to
          post, transmit or upload Communications, you acknowledge that:
        </p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>
            we are not obliged to review, screen or approve Communications;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>Communications may be public; and</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>we may collect and store Communications in any form.</p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.4.</p>
        <p className={css.listItem}>
          In consideration of allowing Communications to be made, posted, transmitted or uploaded,
          you agree that Communications must not:
        </p>
      </p>
      <p />
      <div className={css.container}>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>
            be defamatory, abusive, derogatory, indecent, discriminatory, obscene, threatening,
            harassing, inappropriate;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>breach any law or be used for any unlawful purpose;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>
            be corrupt or contain viruses, worms, Trojan horses, threats, programs or defects which
            may damage computers and software, including websites, apps and computer networks,
            whether ours or of any person;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(d)</p>
          <p className={css.listItem}>be misleading, deceptive, untruthful or incorrect;</p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(e)</p>
          <p className={css.listItem}>
            ransmit spam, junk mail, surveys, chain letters, contests or other mass messages;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(f)</p>
          <p className={css.listItem}>
            breach any other person's intellectual property rights or other rights;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(g)</p>
          <p className={css.listItem}>
            be excessive. What is excessive will be determined by us in our sole discretion;
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(h)</p>
          <p className={css.listItem}>
            detrimentally affect the use of the Website by others or affect our ability to
            administer the Website; nor
          </p>
        </p>
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(i)</p>
          <p className={css.listItem}>
            breach any of our policies referred to in the Website from time to time.
          </p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.5.</p>
        <p className={css.listItem}>
          We may delete or edit Communications, or terminate the ability to make, post, transmit or
          upload Communications, if we believe, in our absolute discretion, that terms or conditions
          in relation to Communications are breached or may be breached.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>5.6.</p>
        <p className={css.listItem}>
          You allow us to use your name or the name you provide to us, for the purposes of the
          Website including that we can attribute to you, your Information.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6.</p>
        <p className={css.listItem}>LIABILITY, RELEASE AND INDEMNITY</p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6.1.</p>
        <p className={css.listItem}>
          Subject to any Australian and New South Wales statutory laws which cannot be excluded and
          subject to any specific Terms to the contrary:
        </p>
      </p>

      <div className={css.container}>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(a)</p>
          <p className={css.listItem}>
            we make no warranty or representation regarding your access to the Website (including
            that it will be uninterrupted, timely, without virus or secure);
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(b)</p>
          <p className={css.listItem}>
            we make no warranty or representation regarding the results of your access to, or use
            of, the Information, the Website, and including any related or linked websites or apps,
            and websites or apps to which there is a diversion;
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(c)</p>
          <p className={css.listItem}>
            we make no warranty in relation any advertised Area, or anything advertised by Posters,
            including any representation or warranty that the thing or Area is of any particular
            quality or fit and safe for the intended purpose;
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(d)</p>
          <p className={css.listItem}>
            we are not responsible for, and make no warranty in relation to, to the terms of any
            contracts offered by the Poster, or entered between a Poster and Occupier:
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(e)</p>
          <p className={css.listItem}>
            we exclude to the maximum extent permitted by law, all liability, claims, loss, damages,
            costs and expenses including legal fees on a full indemnity basis (Losses) and including
            special, indirect or consequential Losses, arising from or related to any use of or
            reliance on the Website or the Information, or arising from any cause of action
            including, any tort (including negligence), error, omission, misrepresentation,
            inability to access or use the Website, breach by any person of the Terms, or other
            reason;
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(f)</p>
          <p className={css.listItem}>
            we disclaim, to the full extent permitted by law, all warranties, representations,
            conditions or endorsements, whether express, implied, statutory or otherwise, related
            to:
          </p>
        </p>
        <p />
        <div className={css.container}>
          <p className={css.listItemContainer}>
            <p className={css.listIndex}>i.</p>
            <p className={css.listItem}>
              the Information, including its accuracy, currency, reliability, truthfulness,
              completeness, and timeliness; or
            </p>
          </p>
          <p />
          <p className={css.listItemContainer}>
            <p className={css.listIndex}>ii.</p>
            <p className={css.listItem}>
              your access to or use of the Website or the Information in any way;
            </p>
          </p>
          <p />
        </div>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(g)</p>
          <p className={css.listItem}>
            you accept that the transmission of data via the internet and electronically is not
            secure and is transmitted at your risk, and we exclude liability for all Losses relating
            to or arising from this risk; and
          </p>
        </p>
        <p />
        <p className={css.listItemContainer}>
          <p className={css.listIndex}>(h)</p>
          <p className={css.listItem}>
            we do not warrant that the Website, Information or these Terms comply with laws of
            countries other than Australia.
          </p>
        </p>
      </div>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6.2.</p>
        <p className={css.listItem}>
          If there is a breach by us of a condition or warranty implied by statutory law which
          cannot be excluded then, at our discretion, our liability is limited to a refund of any
          payment you have made to us or to we may allow you to use the Website again. However, if
          our liability cannot be limited as so stated, then it will be limited to the lowest amount
          allowed by the relevant statutory provision.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6.3.</p>
        <p className={css.listItem}>
          To the maximum extent permitted by law, you release and indemnify us, and will
          indefinitely release and indemnify us, from and against all Losses arising from, or
          related to, the use of or access to the Website and Information, including loss of income,
          revenue, profit, business, data, other economic loss, loss of opportunity, death, personal
          injury and/or damage to property, whether direct, indirect, special or consequential.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>6.4.</p>
        <p className={css.listItem}>
          You indemnify us from and against all Losses arising from your breach of these Terms.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>7.</p>
        <p className={css.listItem}>PERSONAL INFORMATION AND PRIVACY</p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>7.1.</p>
        <p className={css.listItem}>
          You acknowledge that we are not obliged to comply with the Privacy Act 1988 (Cth).
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.</p>
        <p className={css.listItem}>GENERAL</p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.1.</p>
        <p className={css.listItem}>
          A failure, delay or indulgence by us in enforcing any term or condition will not give rise
          to any claim or right of action by any other person and will not be considered a waiver of
          that term or condition.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.2.</p>
        <p className={css.listItem}>
          Should you breach any term or condition and we take no action against you or we waive our
          entitlement to exercise our rights and remedies, we are entitled to exercise our rights
          and remedies in any other situation where you breach the same or any other Terms.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.3.</p>
        <p className={css.listItem}>
          These Terms are governed by the laws applicable in New South Wales, Australia. You submit
          to the non-exclusive jurisdiction of the New South Wales courts.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.4.</p>
        <p className={css.listItem}>
          Unless stated otherwise in the Website, these Terms contain the entire agreement between
          you and us in relation to the Website and its use.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.5.</p>
        <p className={css.listItem}>
          Nothing in these Terms creates the relationship of partnership, principal and agent,
          customer and broker, or joint venture between you and us.
        </p>
      </p>
      <p />
      <p className={css.listItemContainer}>
        <p className={css.listIndex}>8.6.</p>
        <p className={css.listItem}>
          Any term or condition which is illegal, void or unenforceable will be read down so as to
          give it a valid and enforceable operation, and if this is not possible, it will be severed
          from the Terms without affecting the validity or enforceability of the remaining terms and
          conditions.
        </p>
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
