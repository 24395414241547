import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldTextInput,
  Form,
  FieldUpload,
  FieldFileUpload,
  IconSpinner,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import css from './EditListingTeamMembersForm.module.css';
import {
  composeValidators,
  fieldCheckBoxRequired,
  required,
  requiredArrayItems,
} from '../../util/validators';
import { compose } from 'redux';
import { deleteFile, uploadFilePhoto } from '../../containers/EditListingPage/EditListingPage.duck';
import { connect } from 'react-redux';

const EditListingTeamMembersFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        form: {
          mutators: { push, pop },
        },
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        uploading,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        onUploadFile,
        values,
        form,
        currentListingId,
      } = formRenderProps;
      const [loading, setLoading] = useState(false);
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !values?.teamMembers;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTeamMembersForm.updateFailed" />
        </p>
      ) : null;

      const handleUploadFile = (file, index) => {
        setLoading(true);
        onUploadFile(file, index)
          .then(res => {
            setLoading(false);
            console.log('res', res);
            form.change(`teamMembers[${index}].photo`, res?.link);
          })
          .catch(err => {
            setLoading(false);
          });
      };
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTeamMembersForm.showListingFailed" />
        </p>
      ) : null;
      const otherAmenityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.otherAmenityPlaceholder',
      });
      const otherAmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.otherAmenityRequired',
      });
      const AmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingPitchandDealForm.AmenityRequired',
      });
      const imgSrc = index => {
        return values?.teamMembers[index]?.photo
          ? typeof values?.teamMembers[index]?.photo == 'string'
            ? values?.teamMembers[index]?.photo
            : undefined
          : undefined;
      };
      console.log(values);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldTextInput
            id="overview"
            name="overview"
            className={css.description}
            type="textarea"
            label="Overview"
            placeholder="Please give an Overview about your team"
            validate={composeValidators(required('This field is mandatory'))}
          />

          <div>
            Team members
            <FieldArray
              name="teamMembers"
              validate={composeValidators(required('This field is mandatory'))}
            >
              {({ fields }) =>
                fields.map((name, index) => (
                  <div className={css.outerbox} key={name}>
                    <div className={css.innerbox}>
                      <div className={css.profileForm}>
                        <FieldFileUpload
                          name={`${name}.photo`}
                          id={`${name}.photo`}
                          label={
                            <div className="border-2 border-dashed border-yellow-400 rounded-full hover:border-yellow-500 group bg-gray-50 hover:bg-gray-100 transition duration-100 w-64 h-64 flex items-center justify-center">
                              {imgSrc(index) ? (
                                <img
                                  src={imgSrc(index)}
                                  className="h-64 object-cover block w-64 rounded-full"
                                />
                              ) : (
                                <div className="flex items-center flex-col">
                                  {loading ? (
                                    <IconSpinner />
                                  ) : (
                                    // <IoIosAddCircle className="text-yellow-300 text-6xl group-hover:text-yellow-400" />
                                    'Upload image'
                                  )}
                                </div>
                              )}
                            </div>
                          }
                          onSelectFile={file => handleUploadFile(file, index)}
                          uploading={loading}
                          showUploading={false}
                          acceptFiles="images"
                        />
                        <br />
                      </div>
                      <Field
                        className={css.finput}
                        name={`${name}.Name`}
                        component="input"
                        type="text"
                        placeholder="Name"
                        validate={required('Name is required')}
                      />
                      <Field
                        className={css.finput}
                        name={`${name}.linkedin`}
                        component="input"
                        type="text"
                        placeholder="Linkedin"
                        validate={required('Linkedin is required')}
                      />
                      <Field
                        className={css.finput}
                        name={`${name}.postion`}
                        component="input"
                        type="text"
                        placeholder="Postion"
                        validate={required('Postion is required')}
                      />
                      <div className={css.char}>750 characters remaining</div>
                      <Field
                        className={classNames(css.finput, 'resize-none')}
                        name={`${name}.bio`}
                        component="textarea"
                        type="text"
                        rows="2"
                        cols="50"
                        placeholder="Bio"
                      />
                      {/* <Button className={css.savebtn} type="button" disabled={submitDisabled}>
                        Save
                      </Button> */}
                      {/* <textarea
                        className={css.finput}
                        id="w3review"
                        name="w3review"
                        rows="4"
                        cols="50"
                      /> */}
                      <div></div>
                      <span
                        className={css.cancel}
                        onClick={() => fields.remove(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                ))
              }
            </FieldArray>
            <div className={css.btn}>
              <button
                className={classNames(
                  // css.btns,
                  'w-64 bg-blue-500 text-white border-1 border-solid text-sm py-4 px-2 rounded-full font-semibold cursor-pointer hover:bg-blue-600 hover:border-blue-600 border-blue-500 inline-block'
                )}
                type="button"
                onClick={() => push('teamMembers', undefined)}
              >
                {values?.teamMembers?.length > 0 ? 'Add Another Member' : 'Add Members'}
              </button>
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingTeamMembersFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingTeamMembersFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingTeamMembersForm = EditListingTeamMembersFormComponent;
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    onUploadFile: (file, index) => dispatch(uploadFilePhoto(file, index)),
    // onDeleteFile: id => dispatch(deleteFile(id)),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditListingTeamMembersForm);
