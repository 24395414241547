import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { CounterInput } from 'react-rainbow-components';

// function FieldCounterInputComponent(props) {
//   const { input, ...rest } = props;
//   console.log(input);
//   return (
//     <CounterInput
//       {...rest}
//       value={input.value}
//       onChange={input.onChange}
//       onBlur={input.onBlur}
//       // style={containerStyles}
//       className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
//       labelAlignment="center"
//       // value={counter}
//       // onChange={setCounter}
//       variant="shaded"
//     />
//   );
// }

// function FieldCounterInput(props) {
//   console.log(props);
//   return <Field component={<FieldCounterInputComponent {...props} />} />;
// }

// export default FieldCounterInput;

// import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
// import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import FieldNumberCounterInput from '../../components/FieldNumberInput/FieldNumberInput';

// import css from './Count.module.css';

const CONTENT_MAX_LENGTH = 5000;

class FieldCounterInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      max,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    // const inputClasses =
    //   inputRootClass ||
    //   classNames(css.input, {
    //     [css.inputSuccess]: valid,
    //     [css.inputError]: hasError,
    //     [css.textarea]: isTextarea,
    //   });
    const inputProps = { id, type, ...refMaybe, ...input, ...rest };

    // const classes = classNames(rootClassName || css.root, className);
    return (
      <div
        style={{ width: '100%' }}
        //  className={classes}
      >
        {label ? <label htmlFor={id}>{label}</label> : null}
        {/* <CounterInput
          {...inputProps}
          min={0}
          max={max}
          className="border-none rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        /> */}

        <FieldNumberCounterInput {...inputProps} min={0} max={max} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldCounterInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldCounterInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldCounterInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldCounterInputComponent} {...this.props} />;
  }
}

export default FieldCounterInput;
