import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldTextInput,
  Form,
  FieldUpload,
  FieldLogodUpload,
  FieldFileUpload,
  FieldCurrencyInput,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import css from './EditListingDocumentForm.module.css';
import {
  composeValidators,
  fieldCheckBoxRequired,
  required,
  requiredArrayItems,
} from '../../util/validators';
import { compose } from 'redux';

const EditListingDocumentFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        form: {
          mutators: { push, pop },
        },
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        values,
        form,
        currentListingId,
        listingCategory,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const priceRequired = required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const priceValidators = priceRequired;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLogoForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLogoForm.showListingFailed" />
        </p>
      ) : null;
      // const otherAmenityPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.otherAmenityPlaceholder',
      // });
      // const otherAmenityRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.otherAmenityRequired',
      // });
      // const AmenityRequiredMessage = intl.formatMessage({
      //   id: 'EditListingLogoForm.AmenityRequired',
      // });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {/* test
          <FieldFileUpload
            className={css.file}
            name="businessPlan"
            id="businessPlan"
            label="Business Plan test"
            uploading={false}
          /> */}
          <div>
            <h3>Business Plan</h3>
            <FieldUpload
              urlParam={currentListingId}
              parentForm={form}
              publicDataHint="businessPlan"
              parentValues={values}
            />
          </div>
          <div>
            <h3>Executive Summary</h3>
            <FieldUpload
              urlParam={currentListingId}
              parentForm={form}
              publicDataHint="executiveSummary"
              parentValues={values}
            />
          </div>
          <div>
            <h3>Additional Documents</h3>
            <FieldUpload
              urlParam={currentListingId}
              parentForm={form}
              publicDataHint="additionalDocuments"
              parentValues={values}
            />
          </div>
          {listingCategory != 'invest' ? (
            <div>
              {/* <FieldTextInput
                className={css.title}
                id="companyWorth"
                name="companyWorth"
                label="How much company is worth?"
                type="number"
              /> */}
              <FieldCurrencyInput
                id="companyWorth"
                name="companyWorth"
                className={css.title}
                label="How much company is worth?"
                placeholder="Enter Amount"
                currencyConfig={config.currencyConfig}
                validate={composeValidators(
                  required('How much company is worth is Required'),
                  priceValidators
                )}
              />
              <FieldCurrencyInput
                id="grossDividend"
                name="grossDividend"
                className={css.title}
                label="Gross dividend yield"
                placeholder="Enter Amount"
                currencyConfig={config.currencyConfig}
                validate={composeValidators(
                  required('Gross dividend yield is Required'),
                  priceValidators
                )}
              />
              {/* <FieldTextInput
                className={css.title}
                id="grossDividend"
                name="grossDividend"
                label="Gross dividend yield"
                type="number"
              /> */}
            </div>
          ) : null}
          <div>
            <h3></h3>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDocumentFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDocumentFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingDocumentForm = EditListingDocumentFormComponent;

export default compose(injectIntl)(EditListingDocumentForm);
