import React, { useState } from 'react';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import './common.css';
import axios from 'axios';
import { Redirect } from 'react-router';
import { NamedRedirect } from '../../components';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const SubscriptionPaymentForm = ({ currentUser, currentUserIsPremium, onSubscriptionSuccess }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [expirationError, setExpirationError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitting(true);
    setErrorMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    });

    if (payload.error) {
      console.log('[error]', payload.error);
      setErrorMessage(payload.error.message);
    } else {
      const { id } = payload.paymentMethod;
      try {
        const response = await axios.post('/confirm-payment', {
          id,
          userId: currentUser.id.uuid,
        });
        const { client_secret, status } = response.data;
        // setErrorMessage(null);
        // setSuccess(true);

        if (status === 'requires_action') {
          stripe.confirmCardPayment(client_secret).then(function(result) {
            if (result.error) {
              setErrorMessage(result.error.message);
              setSubmitting(false);
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            } else {
              const paymentId = result.paymentIntent.id;
              return onSubscriptionSuccess(paymentId)
                .then(res => {
                  setErrorMessage(null);
                  setSuccess(true);
                  setSubmitting(false);
                  return history.push({
                    pathname: `/success`,
                    state: { userId: currentUser.id.uuid },
                  });
                })
                .catch(e => {
                  setSubmitting(false);
                  console.error(e);
                });
            }
          });
        } else {
          const paymentId = response.data.paymentId;
          return onSubscriptionSuccess(paymentId)
            .then(res => {
              setErrorMessage(null);
              setSuccess(true);
              setSubmitting(false);
              return history.push({
                pathname: `/success`,
                state: { userId: currentUser.id.uuid },
              });
            })
            .catch(e => {
              setSubmitting(false);
              console.error(e);
            });
        }
      } catch (e) {
        setErrorMessage(e.message);
        setSubmitting(false);
      }
    }
  };

  const submitDisabled = !stripe || !name || !postal || submitting;
  return success ? (
    <NamedRedirect
      name="SuccessPaymentPage"
      state={{ paymentSuccess: true, userId: currentUser.id.uuid }}
    />
  ) : currentUserIsPremium ? (
    <Redirect to="/" />
  ) : (
    <form onSubmit={handleSubmit} className="paymentForm">
      <label htmlFor="name">Full Name</label>
      <input
        id="name"
        required
        placeholder="Jenny Rosen"
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
      />
      <label htmlFor="cardNumber">Card Number</label>
      <CardNumberElement
        id="cardNumber"
        options={ELEMENT_OPTIONS}
        className="spacer"
        onChange={e => setCardError(e?.error?.message)}
      />
      {cardError && <span className="stripeError">{cardError}</span>}
      <label htmlFor="expiry">Card Expiration</label>
      <CardExpiryElement
        id="expiry"
        options={ELEMENT_OPTIONS}
        className="spacer"
        onChange={e => {
          setExpirationError(e?.error?.message);
        }}
      />
      {expirationError && <span className="stripeError">{expirationError}</span>}
      <label htmlFor="cvc">CVC</label>
      <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
      <label htmlFor="postal">Postal Code</label>
      <input
        id="postal"
        required
        placeholder="12345"
        value={postal}
        onChange={e => {
          setPostal(e.target.value);
        }}
      />
      {errorMessage && (
        <span style={{ color: 'red', maxWidth: '800px', width: '500px', display: 'inline-block' }}>
          {errorMessage}
        </span>
      )}
      <button type="submit" disabled={submitDisabled}>
        {submitting ? 'Paying ...' : 'Pay $10'}
      </button>
      {/* <small>Please note that you need to pay 20$ to start messaging</small> */}
    </form>
  );
};

export default SubscriptionPaymentForm;
