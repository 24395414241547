import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingCompanyInfoForm } from '../../forms';
import { ListingLink } from '../../components';
import { useInitialValue } from '../../components';
import css from './EditListingCompanyInfoPanel.module.css';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
const FEATURES_NAME = 'amenties';

const EditListingCompanyInfoPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingCategory,
  } = props;
  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { title, publicData, geolocation } = currentListing.attributes;
  console.log(listingCategory);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCompanyInfoPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCompanyInfoPanel.createListingTitle" />
  );
  const normalizeIndustry = data => {
    const result = data?.map(item =>
      config.custom.companyInfoIndustries?.find(k => k.key === item)
    );
    return result;
  };
  const deNormalizeData = data => {
    const result = data?.map(item => item.key);
    return result;
  };
  const location = publicData && publicData.location ? publicData.location : {};

  const locationInitialValue = location
    ? {
        search: location?.address,
        selectedPlace: { address: location?.address, origin: geolocation },
      }
    : null;
  // console.log('locationInitialValue', locationInitialValue);
  let oldInitialValues = {
    title: title,
    website: publicData?.website,
    phoneNumber: publicData?.phoneNumber,
    location: locationInitialValue,
    industry: normalizeIndustry(publicData?.investIndustry),
    stage: config.custom.companyInfoStage.find(item => item.key === publicData?.stage),
    idealInvestor: config.custom.companyInfoIdeal.find(
      item => item.key === publicData?.idealInvestor
    ),
    previousRound: publicData?.previousRound
      ? new Money(publicData?.previousRound, config.currency)
      : null,
    raisingAmountTotal: publicData?.raisingAmountTotal
      ? new Money(publicData?.raisingAmountTotal, config.currency)
      : null,
    raisedAmountTotal: publicData?.raisedAmountTotal
      ? new Money(publicData?.raisedAmountTotal, config.currency)
      : null,
    minimumInvestment: publicData?.minimumInvestment
      ? new Money(publicData?.minimumInvestment, config.currency)
      : null,
  };

  const { initialValues, updateInitialValues } = useInitialValue(oldInitialValues);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingCompanyInfoForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            title,
            website,
            phoneNumber,
            industry,
            stage,
            idealInvestor,
            previousRound,
            raisingAmountTotal,
            raisedAmountTotal,
            minimumInvestment,
            location,
          } = values;

          const {
            selectedPlace: { address, origin },
          } = location;

          const updatedValues = {
            title,
            geolocation: origin,
            publicData: {
              category: listingCategory,
              website,
              minimumInvestment: minimumInvestment?.amount,
              phoneNumber,
              previousRound: previousRound?.amount,
              raisedAmountTotal: raisedAmountTotal?.amount,
              raisingAmountTotal: raisingAmountTotal?.amount,
              stage: stage?.key,
              investIndustry: deNormalizeData(industry),
              idealInvestor: idealInvestor?.key,
              location: { address },
            },
          };
          updateInitialValues({
            ...updatedValues,
            title,
            website,
            phoneNumber,
            industry,
            stage,
            idealInvestor,
            previousRound,
            raisingAmountTotal,
            raisedAmountTotal,
            minimumInvestment,
            location,
          });
          // return;
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingCompanyInfoPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingCompanyInfoPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCompanyInfoPanel;
