import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingDescriptionForm, EditListingBusinessShareForm } from '../../forms';
import config from '../../config';

import css from './EditListingBusinessSharePanel.module.css';

const EditListingBusinessSharePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    params,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    listingCategory,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const category = publicData?.category || params?.category;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  let panelTitle;
  console.log(currentListing.attributes);
  panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBusinessSharePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingBusinessSharePanel.createListingTitle" />
  );
  const stage = publicData?.stage
    ? config.custom.companyInfoStage.find(item => item?.key == publicData?.stage)
    : undefined;
  const idealInvestorRole = publicData?.idealInvestorRole
    ? config.custom.IdealInvestorRole.find(item => item?.key == publicData?.idealInvestorRole)
    : undefined;

  return (
    <div className={classes}>
      <h1 className={css.title}>Business Share</h1>
      <EditListingBusinessShareForm
        className={css.form}
        initialValues={{
          title,
          website: publicData?.website,
          stage: stage,
          other: publicData?.other,
          idealInvestorRole: idealInvestorRole,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, website, stage, idealInvestorRole, other } = values;
          const isother = stage?.key == 'other' ? { other: other } : { other: null };
          const updateValues = {
            title: title.trim(),
            publicData: {
              website,
              category,
              stage: stage?.key,
              ...isother,
              idealInvestorRole: idealInvestorRole.key,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listingCategory={listingCategory}
      />
    </div>
  );
};

EditListingBusinessSharePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingBusinessSharePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBusinessSharePanel;
