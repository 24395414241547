import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldTextInput,
  SecondaryButton,
  FieldSelectModern,
  FieldTimePicker,
  IconAdd,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import css from './EditListingBusinessDetailsForm.module.css';
import {
  composeValidators,
  fieldSelectModernMandatory,
  numberValidate,
  required,
} from '../../util/validators';

export const EditListingBusinessDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        options,
        backButton,
      } = formRenderProps;
      const businessLabelMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.businessLabel',
      });
      const businessPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.businessPlaceholder',
      });
      const businessRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.businessRequired',
      });
      const experienceLabelMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.experienceLabel',
      });
      const experiencePlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.experiencePlaceholder',
      });
      const experienceRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.experienceRequired',
      });
      const industryLabelMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.industryLabel',
      });
      const industryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.industryPlaceholder',
      });
      const industryRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.industryRequired',
      });
      const conditionsMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.conditionMessage',
      });
      const conditionsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.conditionPlaceholderMessage',
      });
      const conditionsRequiredMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.conditionRequiredMessage',
      });
      const addConditionMessage = intl.formatMessage({
        id: 'EditListingBusinessDetailsForm.addConditionMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;
      const numberWithoutFractions = numberValidate(
        'Fractions like 1.5, 3.2, 4.5 etc are not allowed'
      );
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="businessName"
            name="businessName"
            className={css.description}
            type="text"
            label={businessLabelMessage}
            placeholder={businessPlaceholderMessage}
            // validate={composeValidators(required(businessRequiredMessage))}
          />
          {/* <FieldTextInput
            id="yearsOfExperience"
            name="yearsOfExperience"
            className={css.description}
            type="number"
            label={experienceLabelMessage}
            placeholder={experiencePlaceholderMessage}
            validate={composeValidators(
              required(experienceRequiredMessage),
              numberWithoutFractions
            )}
          /> */}
          <FieldSelectModern
            className={css.description}
            id="industry"
            name="industry"
            options={options}
            // isSearchable={false}
            label={industryLabelMessage}
            placeholder={industryPlaceholderMessage}
            validate={fieldSelectModernMandatory(industryRequiredMessage)}
          />
          {/* <FieldTextInput
            id="industry"
            name="industry"
            className={css.description}
            type="text"
            label={industryLabelMessage}
            placeholder={industryPlaceholderMessage}
            validate={composeValidators(required(industryRequiredMessage))}
          /> */}
          {/* condition use fieldArray */}
          <FieldArray name="conditions">
            {({ fields }) => (
              <div>
                <h3 className={css.condition}>{conditionsMessage}</h3>
                {fields.map((name, index) => (
                  <div key={name} className={css.FieldArray}>
                    <FieldTextInput
                      className={css.description}
                      id={`${name}.condition`}
                      name={`${name}.condition`}
                      className={css.description}
                      type="text"
                      placeholder={conditionsPlaceholderMessage}
                      validate={composeValidators(required(conditionsRequiredMessage))}
                    />
                    <span className={css.close} onClick={() => fields.remove(index)}>
                      &times;
                    </span>
                  </div>
                ))}
                <br />
                <SecondaryButton
                  className={css.addButton}
                  type="button"
                  onClick={() => fields.push({ condition: '' })}
                >
                  <IconAdd />
                  <span style={{ paddingLeft: '10px' }}>{addConditionMessage}</span>
                </SecondaryButton>
              </div>
            )}
          </FieldArray>
          <div className={css.buttons}>
            {backButton}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingBusinessDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingBusinessDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingBusinessDetailsFormComponent);
