import React from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.value;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const SearchFilterTypeComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    ...rest
  } = props;

  const { onChange: inputOnChange, ...restOfInput } = input;
  const selectProps = {
    id,
    onChange: handleChange(onChange, inputOnChange),
    ...restOfInput,
    ...rest,
  };

  return (
    <select
      {...selectProps}
      className="bg-gray-50 rounded-tl-[99999px] rounded-bl-[99999px] mx-2 px-4 py-2 h-full max-w-[120px] outline-none border-none"
    >
      {children}
    </select>
  );
};

SearchFilterTypeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

SearchFilterTypeComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const SelectFilterType = props => {
  return <Field component={SearchFilterTypeComponent} {...props} />;
};

export default SelectFilterType;
