import React from 'react';
import css from './FieldNumberInput.module.css';

function FieldNumberCounterInput(props) {
  const {
    id,
    name,
    min,
    max,
    onFocus,
    onBlur,
    value: inputValue,
    onChange,
    placeholder,
    disabled,
  } = props;

  const onIncrement = () => {
    if (inputValue != '') {
      console.log('if', { inputValue });

      const value = parseInt(inputValue) + 1;
      onChange(value);
    } else {
      console.log('else');
      onChange(1);
    }
  };

  const onDecrement = () => {
    if (inputValue != null || inputValue != '' || typeof inputValue != 'undefined') {
      const value = parseInt(inputValue) - 1;
      onChange(value);
    }
  };

  return (
    <div className={css.root}>
      <button
        type="button"
        role="button"
        onClick={onDecrement}
        disabled={inputValue <= min || disabled}
      >
        -
      </button>
      <input
        type="number"
        className={css.input}
        value={inputValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
      />
      <button
        type="button"
        role="button"
        onClick={onIncrement}
        disabled={inputValue >= max || disabled}
      >
        +
      </button>
    </div>
  );
}

export default FieldNumberCounterInput;
