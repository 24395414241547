import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import Slider from 'react-slick';

import css from './SectionHero.module.css';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import SearchForm from '../../containers/LandingPage/SearchForm';
import image1 from '../../assets/herosection/image1.jpg';
import image2 from '../../assets/herosection/image2.jpg';
import image3 from '../../assets/herosection/image3.jpg';
import image4 from '../../assets/herosection/image4.jpg';
import image5 from '../../assets/herosection/image5.jpg';
import image6 from '../../assets/herosection/image6.jpg';
import image7 from '../../assets/herosection/image7.jpg';
import image8 from '../../assets/herosection/image8.jpg';
import image9 from '../../assets/herosection/image9.jpg';
import image10 from '../../assets/herosection/image10.jpg';
import image11 from '../../assets/herosection/image11.jpg';
import image12 from '../../assets/herosection/image12.jpg';
import image13 from '../../assets/herosection/image13.jpg';

const testimonials = [
  {
    img: image2,
    testimony:
      'Spotspace allowed me as a startup to find a physical location for my clothing business without the headache and cost of having to open up a shop',
    user: 'Adam S',
  },

  {
    img: image3,
    testimony:
      'We had so many spare hours at our studio, I’m so glad I found Spotspace, where I was able to connect with other businesses and collaborate! ',
    user: 'Michelle L',
  },
  // {
  //   img: image4,
  //   testimony:
  //     'this is a testimony2 lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum4',
  //   user: 'Charolette',
  // },
  {
    img: image5,
    testimony:
      'Through Spotspace I was able to rent out my surgery to other medical professionals that wanted to work during the hours we were closed. The space is now running at full capacity, which we are so greatful for',
    user: 'Irene T',
  },
  // {
  //   img: image6,
  //   testimony:
  //     'this is a testimony2 lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum6',
  //   user: 'Michael',
  // },
  // {
  //   img: image8,
  //   testimony:
  //     'this is a testimony2 lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum8',
  //   user: 'Joe',
  // },
  // {
  //   img: image9,
  //   testimony:
  //     'this is a testimony2 lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum lorem ipsum dolar sit amet lorem ipsum9',
  //   user: 'Scott',
  // },
  {
    img: image11,
    testimony:
      'my rent was very costly for my kitchen. Through Spotspace I was able to lease my kitchen in the hours I didn’t need it and help cover my costs!',
    user: 'Tass V',
  },
  {
    img: image12,
    testimony:
      'I was excited to list my business on Spotspace and generate extra exposure and income for my business. The support team have been great and very responsive! I have received enquiries already and look forward to expanding business ',
    user: 'Greg A',
  },
  {
    img: image13,
    testimony:
      'Working from home was not very professional as a Marketing and Graphic Design business, specifically for client meetings. I found a local office on Spotspace which I rent a few hours a week as my business location. I have my own set up and pack away, or some weeks I stay for longer periods, thanks to the business owner who is so flexible! ',
    user: ' Natasha K',
  },
];

const SectionHero = props => {
  const { rootClassName, className } = props;
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const classes = classNames(rootClassName || css.root, className);
  const history = useHistory();

  const onSearchSubmit = values => {
    const { type, keyword, location } = values;
    let searchParams = {};
    switch (type) {
      case 'keyword':
        searchParams = { keywords: keyword };
        break;
      case 'location':
        const { search, selectedPlace } = location;
        const { origin, bounds } = selectedPlace;
        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        searchParams = {
          ...originMaybe,
          address: search,
          bounds,
        };
        break;
    }
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <div className={` relative`}>
        <div>
          <div
            className={`relative z-[1] ${css.heroContent}  grid lg:grid-cols-3 container mx-4 sm:mx-auto lg:gap-12`}
          >
            <div className="lg:col-span-2">
              <h1 className={css.heroMainTitle}>
                <FormattedMessage id="SectionHero.title" />
              </h1>
              <h2 className={css.heroSubTitle}>
                <FormattedMessage id="SectionHero.subTitle2" />
              </h2>
            </div>
            <div className="text-white lg:place-self-end mb-6 leading-[1.4] px-4">
              <p className="text-white my-0 leading-snug">
                {testimonials[activeImageIndex]?.testimony}
              </p>
              <p className="text-white my-0 leading-snug">
                ~{testimonials[activeImageIndex]?.user}
              </p>
            </div>
            <div className="w-[90%]  sm:w-[80%] lg:col-span-3 lg:mx-auto">
              <SearchForm onSubmit={onSearchSubmit} />
            </div>
          </div>

          {/* <div className="relative z-1 container mx-auto ">
            <div>
              <h1 className={css.heroMainTitle}>
                <FormattedMessage id="SectionHero.title" />
              </h1>
              <h2 className={css.heroSubTitle}>
                <FormattedMessage id="SectionHero.subTitle2" />
              </h2>
            </div>
          </div> */}
        </div>

        <div className="absolute -z-10 w-full h-full top-0 ">
          <Slider
            {...settings}
            className={css.content}
            afterChange={index => {
              // console.log(index);
              if (typeof index !== 'undefined') setActiveImageIndex(index);
            }}
          >
            {testimonials.map((t, index) => (
              <div key={index} className={css.items}>
                <div
                  style={{
                    backgroundImage: `url(${t.img})`,
                    backgroundSize: 'cover',
                    height: '100vh',
                    width: '100vw',
                    backgroundPosition: 'top center',
                  }}
                  className="relative"
                >
                  <div className="w-full h-full bg-black/50 absolute z-10" />
                  {/* <div className="container mx-auto w-full h-full">
                    <SearchForm onSubmit={onSearchSubmit} />
                  </div> */}

                  {/* <div className={css.testimonial}>
                    <p className="text-white italic tracking-wide text-sm mb-0 ">{t.testimony}</p>
                    <small className="text-white font-semibold mt-0">-{t.user}</small>
                  </div> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* <div className="w-[90%]  sm:w-[80%] absolute top-2/3 lg:top-3/4 left-1/2 -translate-x-1/2 -translate-y-2/3 lg:-translate-y-3/4 z-20">
        <SearchForm onSubmit={onSearchSubmit} />
      </div> */}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
