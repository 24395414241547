import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { BiSearch } from 'react-icons/bi';
import { Form } from '../../components';
import KeywordSearch from './KeywordSearch';
import LocationSearch from './LocationSearch';
import SelectFilterType from './SelectFilterType';

function SearchForm({ onSubmit }) {
  return (
    <FinalForm
      initialValues={{ type: 'location' }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { handleSubmit, values, pristine } = formRenderProps;

        const searchDisabled =
          pristine ||
          (values.type == 'keyword'
            ? typeof values.keyword == 'undefined'
            : typeof values.location?.selectedPlace?.address == 'undefined');

        return (
          <Form onSubmit={handleSubmit}>
            <div className="mx-auto bg-gray-50  h-[60px] border border-gray-200 border-solid rounded-full  flex shadow-md">
              <SelectFilterType id="type" name="type">
                <option value="location">Location</option>
                <option value="keyword">Keyword</option>
              </SelectFilterType>
              {values.type == 'keyword' ? (
                <KeywordSearch id="keyword" name="keyword" placeholder="Search keyword..." />
              ) : (
                <LocationSearch id="location" name="location" />
              )}

              <button
                disabled={searchDisabled}
                className="  rounded-full mr-2 my-1 disabled:bg-gray-400 disabled:border-gray-400 disabled:pointer-events-none hover:cursor-pointer  bg-marketplaceColor inline-block border border-solid border-marketplaceColor text-white font-semibold tracking-wide hover:bg-marketplaceColorDark hover:border-marketplaceColorDark transition ease-in-out duration-200 px-6 "
              >
                <BiSearch className="text-white font-bold text-lg" />
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default SearchForm;
