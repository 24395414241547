import React from 'react';
import { Field } from 'react-final-form';
import { LocationAutocompleteInput } from '../../components';
import css from '../../forms/LocationSearchForm/LocationSearchForm.module.css';
const identity = v => v;

function LocationSearch() {
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          // handleChange(value);
        };

        const searchInput = { ...restInput, onChange: searchOnChange };
        return (
          <LocationAutocompleteInput
            page="landing"
            placeholder={'Search Location...'}
            iconClassName={`${css.searchInputIcon} border-none bg-gray-50 fill-pink-500 hidden`}
            inputClassName={`px-6 inline-block bg-gray-50 h-full border-none ${css.searchInput}`}
            predictionsClassName={css.searchPredictions}
            input={searchInput}
            meta={meta}
          />
        );
      }}
    />
  );
}

export default LocationSearch;
