import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingRequestedTimingForm } from '../../forms';
import config from '../../config';

import css from './EditListingRequestedTimingPanel.module.css';
const EditListingRequestedTimingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingCategory,
    backButton,
  } = props;
  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingRequestedTimingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingRequestedTimingPanel.createListingTitle" />
  );

  let initialValues = {
    startDate: publicData?.requestedTiming?.startDate
      ? new Date(publicData?.requestedTiming?.startDate)
      : null,
    endDate: publicData?.requestedTiming?.endDate
      ? new Date(publicData?.requestedTiming?.endDate)
      : null,
  };
  if (updateInProgress) {
    initialValues = { ...initialValues, ...initialProps };
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingRequestedTimingForm
        className={css.form}
        publicData={publicData}
        initialValues={initialValues}
        onSubmit={values => {
          let { startDate, endDate } = values;
          const start = startDate?.toISOString();
          const end = endDate.toISOString();
          const requestedTiming = {
            startDate: start,
            endDate: end,
          };
          const updateValues = {
            publicData: {
              requestedTiming,
            },
          };
          setInitialProps({
            ...updateValues.publicData,
            startDate,
            endDate,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listingCategory={listingCategory}
        backButton={backButton}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingRequestedTimingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingRequestedTimingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingRequestedTimingPanel;
