import React from 'react';
import { Field } from 'react-final-form';
import { IoCaretDownOutline } from 'react-icons/io5';

function TailwindSelectComponent({ input, meta, options, simple, children, ...rest }) {
  if (children) {
    const { onChange, value, className } = rest;
    return (
      <div
        className="relative focus-within:text-gray-700 ml-1"
        style={{ maxWidth: 'calc(100% - 50px)' }}
      >
        {/* <IoCaretDownOutline className="absolute right-[1rem] top-1/2 -translate-y-1/2" /> */}
        <select value={value} onChange={onChange} className={`${className} appearance-none`}>
          {children}
        </select>
      </div>
    );
  }

  if (simple) {
    const { onChange, value, className } = rest;
    return (
      <div
        className="relative focus-within:text-gray-700 ml-1"
        style={{ maxWidth: 'calc(100% - 50px)' }}
      >
        <IoCaretDownOutline className="absolute right-[1rem] top-1/2 -translate-y-1/2" />
        <select value={value} onChange={onChange} className={className}>
          <option value="" hidden>
            Please choose an option...
          </option>
          {options.map(t =>
            typeof t == 'object' ? (
              <option value={t.id} key={t.id}>
                {t.team_name}
              </option>
            ) : (
              <option value={t} key={t}>
                {t}
              </option>
            )
          )}
        </select>
      </div>
    );
  }

  return (
    <div
      className="relative focus-within:text-gray-700 ml-1"
      style={{ maxWidth: 'calc(100% - 50px)' }}
    >
      <IoCaretDownOutline className="absolute right-[1rem] top-1/2 -translate-y-1/2" />

      <select {...rest} {...input}>
        <option value="" hidden>
          Please choose an option...
        </option>
        {options.map(t =>
          typeof t == 'object' ? (
            <option value={t.id} key={t.id}>
              {t.team_name}
            </option>
          ) : (
            <option value={t} key={t}>
              {t}
            </option>
          )
        )}
      </select>
    </div>
  );
}

function TailwindSelect({ simple, ...rest }) {
  if (simple) return <TailwindSelectComponent simple {...rest} />;
  return <Field type="select" component={TailwindSelectComponent} {...rest} />;
}

export default TailwindSelect;
