import React, { useState, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, IconSpinner } from '../../components';
import classNames from 'classnames';

import css from './FileUpload.module.css';
import { deleteFile, uploadFile } from '../../containers/EditListingPage/EditListingPage.duck';

const ACCEPT_FILES =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpg, image/jpeg,image/png';
const cloudStorageBucket = process.env.REACT_APP_GOOGLE_STORAGE_BUCKET;

function FieldUpload(props) {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [Id, setId] = useState(null);
  // useEffect(() => {
  //   const { currentUser } = props;
  //   const user = ensureCurrentUser(currentUser);
  //   if (user) {
  //     const resumeLink =
  //       user.attributes.profile.publicData && user.attributes.profile.publicData.resumeLink;
  //     const resumeId =
  //       user.attributes.profile.publicData && user.attributes.profile.publicData.resumeId;

  //     setUploadedFileData({
  //       resumeLink,
  //       resumeId,
  //     });
  //   }
  // }, [props]);

  return (
    <FinalForm
      {...props}
      isFileUploaded={isFileUploaded}
      setIsFileUploaded={setIsFileUploaded}
      uploadedFileData={uploadedFileData}
      setUploadedFileData={setUploadedFileData}
      uploading={uploading}
      setUploading={setUploading}
      onSubmit={console.log}
      render={formRenderProps => {
        const {
          form,
          onUploadFile,
          setIsFileUploaded,
          uploadedFileData,
          setUploadedFileData,
          uploading,
          setUploading,
          values,
          onDeleteFile,
          urlParam,
          publicDataHint,
          parentForm,
          parentValues,
        } = formRenderProps;

        const deleteFile = id => {
          console.log(id);
          const imageId = id.replace(`https://storage.googleapis.com/coachability/`, '');
          console.log(imageId);
          console.log(id.replace(`https://storage.googleapis.com/coachability/`, ''));

          onDeleteFile({ id: imageId, listingId: urlParam, key: publicDataHint })
            .then(res => {
              parentForm.change(`${publicDataHint}`, '');
              form.change(`${publicDataHint}-file`, '');
              setUploadedFileData(null);
            })
            .catch(err => console.log(err));
        };

        // console.log(publicDataHint);
        return (
          <Form onSubmit={console.log}>
            {parentValues && parentValues[publicDataHint] && (
              <div className={css.cvContainer}>
                <a href={parentValues[publicDataHint]}>
                  {parentValues[publicDataHint]?.replace(
                    `https://storage.googleapis.com/${cloudStorageBucket}/`,
                    ' '
                  )}
                </a>
                {/* <img src={parentValues[publicDataHint]} alt="image" width="400" height="500" /> */}
                <span
                  className={css.cvDelete}
                  onClick={() =>
                    deleteFile(
                      // parentValues[publicDataHint]?.replace(
                      //   `https://storage.googleapis.com/${cloudStorageBucket}/`,
                      //   ' '
                      // )
                      parentValues[publicDataHint]
                    )
                  }
                >
                  &times;
                </span>
              </div>
            )}
            <Field
              id={`${publicDataHint}-file`}
              name={`${publicDataHint}-file`}
              accept={ACCEPT_FILES}
              form={null}
              label={
                values[`${publicDataHint}-file`]
                  ? values[`${publicDataHint}-file`].name
                  : 'Click to Upload File'
              }
              type="file"
              disabled={uploading || parentValues[publicDataHint]}
            >
              {fieldprops => {
                const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const onChange = e => {
                  const file = e.target.files[0];
                  form.change(`${publicDataHint}-file`, file);
                  form.blur(`${publicDataHint}-file`);
                  setUploading(true);
                  console.log(file);

                  onUploadFile({ urlParam, publicDataHint }, file)
                    .then(res => {
                      //console.log('res', res.certificatesId, publicDataHint);
                      // form.change(`${publicDataHint}-file`, '');
                      setIsFileUploaded(true);
                      setId(res.Id);
                      parentForm.change(`${publicDataHint}`, res[publicDataHint]);
                      setUploading(false);
                    })
                    .catch(err => {
                      // form.change(`${publicDataHint}-file`, '');
                      setUploading(false);
                      setIsFileUploaded(false);
                    });
                };
                const inputProps = {
                  accept,
                  id: name,
                  name,
                  onChange,
                  type,
                };
                return (
                  <div
                    className={classNames(
                      css.addImageWrapper,
                      parentValues[publicDataHint] ? css.disabledField : ''
                    )}
                    style={{ float: 'unset', width: '100%', height: '100px', position: 'relative' }}
                  >
                    <div className={css.aspectRatioWrapper}>
                      {fieldDisabled ? null : (
                        <input {...inputProps} className={css.addImageInput} />
                      )}
                      <label htmlFor={name} className={css.addImage}>
                        {label}
                      </label>
                    </div>
                    {uploading && (
                      <div className={css.loadingContainer}>
                        <IconSpinner className={css.spinner} />
                      </div>
                    )}
                  </div>
                );
              }}
            </Field>
          </Form>
        );
      }}
    />
  );
}

const mapStateToProps = state => {
  // const currentUser = state.user.currentUser;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadFile: (extraData, file) => dispatch(uploadFile(extraData, file)),
    onDeleteFile: id => dispatch(deleteFile(id)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FieldUpload);
