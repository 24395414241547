import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldTextInput, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { composeValidators, fieldCheckBoxRequired, required } from '../../util/validators';
import { compose } from 'redux';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        intl,
        invalid,
        values,
        backButton,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;
      const otherAmenityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.otherAmenityPlaceholder',
      });
      const otherAmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.otherAmenityRequired',
      });
      const AmenityRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.AmenityRequired',
      });

      const options = config.custom.amenties;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            flexColumnsInMobile={true}
            id={name}
            name={name}
            options={options}
            twoColumns={options?.length > 10}
            validate={fieldCheckBoxRequired(AmenityRequiredMessage)}
          />
          {values?.amenties?.includes('other') ? (
            <FieldTextInput
              id="otherAmenity"
              name="otherAmenity"
              className={css.title}
              type="text"
              placeholder={otherAmenityPlaceholderMessage}
              validate={composeValidators(required(otherAmenityRequiredMessage))}
            />
          ) : null}

          <div className={css.buttons}>
            {backButton}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
