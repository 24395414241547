import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import config from '../../config';

import css from './EditListingPoliciesPanel.module.css';
const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingCategory,
    id,
    editListingLinkType,
    slug,
    backButton,
  } = props;
  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const spaceTypes = config.custom.spaceTypes;
  const usageTypes = config.custom.usage;
  const lengthTypes = config.custom.lengthTypes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  let panelTitle;
  if (listingCategory === config.REQUIREMENT) {
    panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPoliciesPanel.requirementListingtitle"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPoliciesPanel.createRequirementListingTitle" />
    );
  } else {
    panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPoliciesPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
    );
  }
  function deNormalizeValue(item, dataArray) {
    return dataArray.find(a => a?.value === item);
  }
  const normalizeSpaceType = item => item?.map(i => spaceTypes?.find(a => a.key === i));
  const normalizeUsageType = item => item?.map(i => usageTypes?.find(a => a.key === i));

  let initialValues = {};
  publicData.spaceType
    ? (initialValues.spaceType = normalizeSpaceType(
        Array.isArray(publicData.spaceType) ? publicData.spaceType : [publicData.spaceType]
      ))
    : null;
  publicData.otherSpaceType ? (initialValues.otherSpaceType = publicData.otherSpaceType) : null;
  publicData.spaceType ? (initialValues.sizeOfSpace = publicData.sizeOfSpace / 10000000) : null;
  publicData.capacityOfSpace
    ? (initialValues.capacityOfSpace = publicData.capacityOfSpace / 10000000)
    : null;
  publicData.usage
    ? (initialValues.usage = normalizeUsageType(
        Array.isArray(publicData.usage) ? publicData.usage : [publicData.usage]
      ))
    : [];
  publicData.length
    ? (initialValues.length = deNormalizeValue(publicData.length, lengthTypes))
    : null;
  // publicData.openingHours ? (initialValues.openingHours = publicData?.openingHours) : null;
  publicData.otherUsage ? (initialValues.otherUsage = publicData?.otherUsage) : null;
  if (updateInProgress) {
    initialValues = { ...initialValues, ...initialProps };
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        publicData={publicData}
        initialValues={initialValues}
        onSubmit={values => {
          let {
            spaceType,
            sizeOfSpace,
            capacityOfSpace,
            usage,
            length,
            // openingHours,
            otherUsage,
            otherSpaceType,
          } = values;
          const space = sizeOfSpace ? Number(sizeOfSpace) * 10000000 : null;
          const capacity = capacityOfSpace ? Number(capacityOfSpace) * 10000000 : null;

          // listingCategory === config.REQUIREMENT ? (openingHours = null) : [...openingHours];
          const updateValues = {
            publicData: {
              spaceType: spaceType?.map(i => i.key),
              sizeOfSpace: space,
              capacityOfSpace: capacity,
              usage: usage?.map(i => i.key),
              length: length.value,
              otherUsage,
              otherSpaceType: otherSpaceType,
              //  spaceType?.map(i => i.key)?.includes('other') ? otherSpaceType : null,
              // openingHours,
            },
          };
          setInitialProps({
            spaceType,
            sizeOfSpace,
            capacityOfSpace,
            usage,
            length,
            otherSpaceType,
            // openingHours,
            otherUsage,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        spaceTypes={spaceTypes}
        usageTypes={usageTypes}
        lengthTypes={lengthTypes}
        listingCategory={listingCategory}
        id={id}
        slug={slug}
        editListingLinkType={editListingLinkType}
        backButton={backButton}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
