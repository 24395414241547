import React, { Component } from 'react';
import { bool } from 'prop-types';
// import SelectSingleFilterPlain from './SelectSingleFilterPlain';
// import AvailabilityFilterPopup from './AvailabilityFilterPopup';
import FilterPopup from '../FilterPopup/FilterPopup';
import FieldAirbnbDateTimePicker from '../FieldAirbnbDateTimePicker/FieldAirbnbDateTimePicker';
import FilterPlain from '../FilterPlain/FilterPlain';
import css from './AvailabilityFilterPopup.module.css';
import moment from 'moment';
import { injectIntl } from '../../util/reactIntl';

class AvailabilityFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasStart: false,
    };

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      label,
      id,
      contentPlacementOffset,
      name,
      showAsPopup,
      onSubmit,
      urlQueryParams,
      rootClassName,
      className,
      queryParamNames,
      intl,
      ...rest
    } = this.props;
    function classNames() {
      var classes = [];

      for (var i = 0; i < arguments.length; i++) {
        var arg = arguments[i];
        if (!arg) continue;

        var argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
          classes.push(arg);
        } else if (Array.isArray(arg) && arg.length) {
          var inner = classNames.apply(null, arg);
          if (inner) {
            classes.push(inner);
          }
        } else if (argType === 'object') {
          for (var key in arg) {
            if (hasOwn.call(arg, key) && arg[key]) {
              classes.push(key);
            }
          }
        }
      }

      return classes.join(' ');
    }
    const getQueryParamName = queryParamNames => {
      return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
    };
    const classes = classNames(rootClassName || css.root, className);

    const handleFormSubmit = values => {
      // const { start, end } = values;
      // console.log(values);

      if (values == null) {
        this.setState({ hasStart: false, start: null });
        return onSubmit({ start: undefined, end: undefined, availability: undefined });
      }

      const onCancel = typeof values.pub_test == 'undefined';

      const start = values?.start;
      const end = values?.end;
      const payload =
        start && end
          ? {
              start: Array?.isArray(start)
                ? new Date(start[0])?.toISOString()
                : new Date(start)?.toISOString(),
              end: Array?.isArray(end)
                ? new Date(end[0])?.toISOString()
                : new Date(end)?.toISOString(),
              // availability: 'time-full',
              availability: 'time-partial',
            }
          : undefined;
      if (start) {
        this.setState({
          hasStart: true,
          start: Array?.isArray(start)
            ? new Date(start[0])?.toISOString()
            : new Date(start)?.toISOString(),
        });
      }

      if (payload) {
        return onSubmit(payload);
      }
      if (onCancel) return onSubmit(null);
    };
    const initialValues = {
      end: urlQueryParams?.['end'] ? Date.parse(new Date(urlQueryParams.end)) : undefined,
      start: urlQueryParams?.['start'] ? Date.parse(new Date(urlQueryParams.start)) : undefined,
    };
    // console.log('initialValues', initialValues);
    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues['start'] && !!initialValues['end'];

    console.log({ hasInitialValues });

    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    // const selectedOptions = hasInitialValues
    //   ? parseSelectFilterOptions(initialValues[queryParamName])
    //   : [];
    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: hasInitialValues ? 1 : 0 }
        )
      : label;
    const contentStyle = this.positionStyleForContent();

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { labelText: label, count: hasInitialValues ? 1 : 0 }
        )
      : label;
    const namedInitialValues = { [name]: undefined };

    let currentDate = moment(new Date());
    const dateMinutes = currentDate.clone().minutes();
    const minutes = dateMinutes % 5;
    let minutesToSet = 0;

    switch (minutes) {
      case 0:
        minutesToSet = 0;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        minutesToSet = 5;
        break;
    }

    const startMinutes = parseInt(dateMinutes / 5, 10) * 5 + minutesToSet;
    // const endMinutes = startMinutes + 5;

    currentDate = currentDate
      .set({
        minutes: startMinutes,
        seconds: 0,
        milliseconds: 0,
      })
      .toISOString();

    currentDate = new Date(currentDate);

    const endTime = this.state.start ?? currentDate;
    const endTimeMinutes = moment(endTime).minutes();
    const endTimeMinutesRemainder = endTimeMinutes % 5;
    let endTimeMinutesToSet = 0;

    switch (endTimeMinutesRemainder) {
      case 0:
        endTimeMinutesToSet = 0;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        endTimeMinutesToSet = 5;
        break;
    }

    const endMinutes = endTimeMinutesToSet + endTimeMinutes + 5;

    const endDate = new Date(
      moment(endTime)
        .clone()
        .set({
          minutes: endMinutes,
          seconds: 0,
          milliseconds: 0,
        })
        .toISOString()
    );

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleFormSubmit}
        urlQueryParams={urlQueryParams}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <div className={css.dateTime}>
          <div className={css.level}>
            <FieldAirbnbDateTimePicker
              id="start"
              name="start"
              placeholder="Start date time"
              minDate={currentDate}
            />
          </div>
          <div className={css.level}>
            <FieldAirbnbDateTimePicker
              id="end"
              name="end"
              placeholder="End date time"
              minDate={endDate}
            />
          </div>
        </div>
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleFormSubmit}
        {...rest}
        initialValues={{
          end: urlQueryParams?.['end'] ? Date.parse(new Date(urlQueryParams.end)) : undefined,
          start: urlQueryParams?.['start'] ? Date.parse(new Date(urlQueryParams.start)) : undefined,
        }}
      >
        <div className={css.dateTime}>
          <div className={css.level}>
            <FieldAirbnbDateTimePicker
              id="start"
              name="start"
              placeholder="Start date time"
              minDate={currentDate}
            />
          </div>
          <div className={css.level}>
            <FieldAirbnbDateTimePicker
              id="end"
              name="end"
              placeholder="End date time"
              minDate={endDate}
              disabled={!this.state.hasStart}
            />
          </div>
        </div>
      </FilterPlain>
    );
  }
  //     <AvailabilityFilterPopup {...rest} onSubmit={handleFormSubmit} initialValues={initialValues} />
  //   ) : (
  //     <AvailabilityFilterPopup {...rest} onSubmit={handleFormSubmit} />
  //   );
  //     <AvailabilityFilterPopup {...rest} onSubmit={handleFormSubmit} initialValues={initialValues} />
  //   ) : (
  //     <AvailabilityFilterPopup {...rest} onSubmit={handleFormSubmit} />
  //   );
  // };
  // };
}

AvailabilityFilter.defaultProps = {
  showAsPopup: false,
};

AvailabilityFilter.propTypes = {
  showAsPopup: bool,
};

export default injectIntl(AvailabilityFilter);
