import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckboxGroup,
  NamedLink,
  FieldCheckbox,
} from '../../components';
import css from './EditListingPricingForm.module.css';
import arrayMutators from 'final-form-arrays';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        category,
        backButton,
      } = formRenderProps;
      console.log(values);
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });
      const dailyPricePerUnitMessage = intl.formatMessage({
        id: 'EditListingPricingForm.dailyPriceInputLabel',
      });

      const dailyPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.dailyPriceInputPlaceholder',
      });
      const monthlyPricePerUnitMessage = intl.formatMessage({
        id: 'EditListingPricingForm.monthlyPriceInputLabel',
      });

      const monthlyPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.monthlyPriceInputPlaceholder',
      });
      const weeklyPricePerUnitMessage = intl.formatMessage({
        id: 'EditListingPricingForm.weeklyPriceInputLabel',
      });

      const weeklyPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.weeklyPriceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const includes = type => values?.pricing_options?.includes(type);

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          {category != config.REQUIREMENT ? (
            <>
              {/* <FieldCheckbox
                id="enquire_only"
                name="enquire_only"
                value="enquire_only"
                label={intl.formatMessage({
                  id: 'EditListingPricingForm.EditListingPricingForm.enquireOnly',
                })}
              /> */}

              <FieldCheckboxGroup
                className={css.features}
                id="pricing_options"
                name="pricing_options"
                options={
                  values?.pricing_options?.includes('enquire_only')
                    ? config.custom.pricingOptions.filter(item => item.key == 'enquire_only')
                    : config.custom.pricingOptions
                }
                twoColumns={true}
                validate={validators.fieldCheckBoxRequired('Please select at least one option')}
              />
              {includes('hourly') && !values?.pricing_options?.includes('enquire_only') ? (
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.priceInput}
                  label={pricePerUnitMessage}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
              ) : null}
              {includes('daily') && !values?.pricing_options?.includes('enquire_only') ? (
                <FieldCurrencyInput
                  id="daily_price"
                  name="daily_price"
                  className={css.priceInput}
                  label={dailyPricePerUnitMessage}
                  placeholder={dailyPricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
              ) : null}
              {includes('weekly') && !values?.pricing_options?.includes('enquire_only') ? (
                <FieldCurrencyInput
                  id="weekly_price"
                  name="weekly_price"
                  className={css.priceInput}
                  label={weeklyPricePerUnitMessage}
                  placeholder={weeklyPricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
              ) : null}
              {includes('monthly') && !values?.pricing_options?.includes('enquire_only') ? (
                <FieldCurrencyInput
                  id="monthly_price"
                  name="monthly_price"
                  className={css.priceInput}
                  label={monthlyPricePerUnitMessage}
                  placeholder={monthlyPricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
              ) : null}
              {values?.pricing_options?.includes('enquire_only') ? null : (
                <FieldCurrencyInput
                  id="minimum_spend"
                  name="minimum_spend"
                  className={css.priceInput}
                  label={intl.formatMessage({
                    id: 'EditListingPricingForm.EditListingPricingForm.minimumSpendLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.EditListingPricingForm.minimumSpendPlaceholder',
                  })}
                  currencyConfig={config.currencyConfig}
                />
              )}
            </>
          ) : (
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              autoFocus
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          )}

          <div className={css.buttons}>
            {backButton}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              // ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
