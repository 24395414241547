import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingBusinessDetailsForm } from '../../forms';
import config from '../../config';

import css from './EditListingBusinessDetailsPanel.module.css';

const EditListingBusinessDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backButton,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBusinessDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingBusinessDetailsPanel.createListingTitle" />
  );
  const industryOptions = config.custom.industry;
  function deNormalizeValue(item, dataArray) {
    return dataArray.find(a => a?.value === item);
  }
  const initialValues = {
    businessName: publicData.businessName,
    // yearsOfExperience: publicData.yearsOfExperience,
    industry: deNormalizeValue(publicData.industry, industryOptions),
    conditions: publicData.conditions,
  };
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingBusinessDetailsForm
        className={css.form}
        publicData={publicData}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            businessName,
            //  yearsOfExperience,
            industry,
            conditions = [],
          } = values;

          const updateValues = {
            publicData: {
              businessName,
              // yearsOfExperience,
              industry: industry.value,
              conditions,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        options={industryOptions}
        backButton={backButton}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingBusinessDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingBusinessDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBusinessDetailsPanel;
