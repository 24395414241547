import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed } from '../../components';

import css from './TransactionPanel.module.css';
import moment from 'moment';

const generateDataFromBooking = (currentTransaction, unit, differenceInHours) => {
  const booking = currentTransaction.booking.attributes;
  const variant = currentTransaction.attributes.protectedData.variant;
  const { start, end } = booking;
  // console.log({
  //   start,
  //   end,
  //   weeks,
  //   variant: currentTransaction.attributes.protectedData.variant,
  //   differenceInHours,
  // });

  if (!unit) return null;

  const quantity = (variant == 'weekly' ? 7 * unit : 30 * unit) - 1;

  let startDate = moment(start).valueOf();
  let endLimit = moment(end)
    .add(quantity, 'days')
    // .add(differenceInHours, 'hours')
    .subtract(1, 'millisecond')
    .valueOf();

  return {
    dates: {
      from: moment(startDate).format('DD-MM-YYYY'),
      to: moment(endLimit).format('DD-MM-YYYY'),
    },
    times: {
      from: moment(startDate).format('HH:mm'),
      to: moment(end).format('HH:mm'),
    },
  };
};

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
  } = props;

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  const { weeks, months, differenceInHours } = currentTransaction?.attributes?.protectedData ?? {};
  const hasData = currentTransaction?.attributes?.protectedData?.data;
  const data = hasData ? JSON.parse(hasData) : {};
  const { quantity, variant, calendar, ...rest } = data;

  const hasDailyData = currentTransaction?.attributes?.protectedData?.days;
  const dailyData = hasDailyData ? JSON.parse(hasDailyData) : {};

  const hasWeeklyData = currentTransaction?.attributes?.protectedData?.weeks;
  const weeklyData = hasWeeklyData
    ? generateDataFromBooking(currentTransaction, weeks, differenceInHours)
    : {};
  const hasMonthlyData = currentTransaction?.attributes?.protectedData?.months;
  const monthlyData = hasMonthlyData
    ? generateDataFromBooking(currentTransaction, months, differenceInHours)
    : {};

  // console.log(currentTransaction);

  return showFeed ? (
    <div className={classes}>
      <h3 className={css.feedHeading}>
        <FormattedMessage id="TransactionPanel.activityHeading" />
      </h3>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      <ActivityFeed
        className={css.feed}
        messages={messages}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onOpenReviewModal={onOpenReviewModal}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
      />
      {hasData && (
        <div className="bg-gray-100 mt-4 border-[2px] border-solid border-gray-500 rounded-md shadow-md overflow-hidden">
          <h4 className="text-lg p-3 my-0 bg-gray-300 text-gray-700 border-0 border-b-[2px] border-solid border-gray-500 uppercase font-semibold">
            Slot Timings{' '}
          </h4>
          <div className="p-3 pt-0 pb-0">
            {(Object.keys(rest) || [])?.map(entry => {
              return (
                <div className="mb-2" key={entry}>
                  <p className="my-0 mt-2">
                    <strong>Date: {entry}</strong>
                  </p>
                  {rest[entry]?.map(s => {
                    return (
                      <div>
                        <span>{moment(+s.startTime).format('HH:mm')}</span>
                        <span>-</span>
                        <span>{moment(+s.endTime).format('HH:mm')}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {hasDailyData && (
        <div className="bg-gray-100 mt-4 border-[2px] border-solid border-gray-500 rounded-md shadow-md overflow-hidden">
          <h4 className="text-lg p-3 my-0 bg-gray-300 text-gray-700 border-0 border-b-[2px] border-solid border-gray-500 uppercase font-semibold">
            Days{' '}
          </h4>
          <div className="p-3 pt-2 pb-2 flex flex-wrap">
            {[...(dailyData || [])]?.map(day => {
              return (
                <div
                  className="bg-white mx-2 flex justify-center shadow px-4 py-2 my-2 rounded-full"
                  style={{ flexBasis: '30%' }}
                  key={day}
                >
                  <p className="my-0">
                    <strong>{moment(day).format('DD-MM-YYYY')}</strong>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {hasWeeklyData && (
        <div className="bg-gray-100 mt-4 border-[2px] border-solid border-gray-500 rounded-md shadow-md overflow-hidden">
          <h4 className="flex justify-between text-lg p-3 my-0 bg-gray-300 text-gray-700 border-0 border-b-[2px] border-solid border-gray-500 uppercase font-semibold">
            <span>From: {weeklyData.dates.from}</span>
            <span>To: {weeklyData.dates.to}</span>
          </h4>
          <div className="p-3 leading-wide text-sm font-semibold">
            {weeklyData?.times.from == weeklyData?.times.to ? (
              'Full Day'
            ) : (
              <>
                From Time: {weeklyData.times.from} <br />
                To Time: {weeklyData.times.to}
              </>
            )}
          </div>
        </div>
      )}
      {hasMonthlyData && (
        <div className="bg-gray-100 mt-4 border-[2px] border-solid border-gray-500 rounded-md shadow-md overflow-hidden">
          <h4 className="flex justify-between text-lg p-3 my-0 bg-gray-300 text-gray-700 border-0 border-b-[2px] border-solid border-gray-500 uppercase font-semibold">
            <span>From: {monthlyData.dates.from}</span>
            <span>To: {monthlyData.dates.to}</span>
          </h4>
          <div className="p-3 leading-wide text-sm font-semibold">
            {monthlyData?.times.from == monthlyData?.times.to ? (
              'Full Day'
            ) : (
              <>
                From Time: {monthlyData.times.from} <br />
                To Time: {monthlyData.times.to}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default FeedSection;
