import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const RangeMoment = extendMoment(moment);

export const groupSimilarTimeWithZeroDifferenceTogether = slots => {
  return Object.keys(slots)
    .map(dateKey => {
      const dateArray = slots[dateKey];

      let prev = null;
      let ranges = [];
      const lastIndex = dateArray.length - 1;
      dateArray.forEach((date, index) => {
        if (prev == null) {
          prev = date;
        } else if (prev.end == date.start) {
          prev = { ...prev, end: date.end, endTimeOfDay: date.endTimeOfDay, endDay: date.endDay };
        } else {
          ranges.push(prev);
          ranges.push(date);
          prev = date;
        }

        if (lastIndex == index) {
          ranges.push(prev);
        }
      });
      return ranges;
    })
    .reduce((acc, curr) => acc.concat(curr), []);
};

export const makeRangeFromSlots = slots => {
  // console.log(slots);

  let startPointer = null;
  let previous = null;
  let range = [];
  for (let key of Object.keys(slots)) {
    for (let item of slots[key]) {
      if (startPointer && previous && previous.end - item.start != 0) {
        range.push({
          ...startPointer,
          end: item.end,
          endTimeOfDay: item.endTimeOfDay,
          endDay: item.endDay,
        });
        previous = null;
        startPointer = null;
      }

      if (previous == null && startPointer == null) {
        previous = item;
        startPointer = item;
      } else {
        previous = item;
      }
    }
    if (startPointer && previous) {
      range.push({
        ...startPointer,
        end: previous.end,
        endDay: previous.endDay,
        endTimeOfDay: previous.endTimeOfDay,
      });
    }
    previous = null;
    startPointer = null;
  }
  return makeRangeFromDays(range);
};

export const makeRangeFromDays = days => {
  let startPointer = null;
  let previous = null;
  let range = [];

  for (let item of days) {
    if (startPointer && previous && previous.end - item.start != 0) {
      range.push({
        ...startPointer,
        end: item.end,
        endTimeOfDay: item.endTimeOfDay,
        endDay: item.endDay,
      });
      previous = null;
      startPointer = null;
    }

    if (previous == null && startPointer == null) {
      previous = item;
      startPointer = item;
    } else {
      previous = item;
    }
  }
  if (startPointer && previous) {
    range.push({
      ...startPointer,
      end: previous.end,
      endDay: previous.endDay,
      endTimeOfDay: previous.endTimeOfDay,
    });
  }
  previous = null;
  startPointer = null;

  return range;
};

export const checkIfExistsInRange = (range, item) => {
  let exists = false;
  for (const rangeItem of range) {
    const momentRange = RangeMoment.range(
      `${moment(rangeItem.start).toISOString()}/${moment(rangeItem.end).toISOString()}`
    );

    if (
      momentRange.contains(moment(item.startTime)) &&
      momentRange.contains(moment(item.endTime))
    ) {
      exists = true;
      break;
    }

    // if (item.startTime >= rangeItem.start && item.endTime <= rangeItem.end) {
    //   exists = true;
    //   console.log({
    //     range: { start: new Date(rangeItem.start), end: new Date(rangeItem.end) },
    //     item: { start: new Date(item.startTime), end: new Date(item.endTime) },
    //   });
    //   break;
    // }
  }
  console.log({ range, item, exists });

  return exists;
};
